import 'tracking/tracking';
import 'tools/nonRenderblockingCss';
import 'tools/various';
import 'tools/autocomplete';
import 'tools/tab';
import 'tools/modals';
import 'tools/floatlabels_custom';
import 'tools/navigation';
import 'tools/notifications';
import { DomReady } from 'tools/domready';
import { setLocalStorage } from 'tools/setLocalStorage';
import { initModalTogglers } from 'tools/modals';
import { isMobile } from 'tools/isMobile';
import { DynamicScript } from 'globals/DynamicScript';
import { dataLayer } from 'globals/datalayerPusher';

initModalTogglers();
DomReady(() => {
    setLocalStorage();
    checkForLogout();
    checkForLogin();
});

/**
 * Dynamic Imports
 */

if (isMobile()) {
    import(/* webpackChunkName: "mobile" */ 'mobile/footer').then(
        exports => exports.initMobileFooter()
    );
}

if (document.querySelector('[data-newsfeed]')) {
    import(/* webpackChunkName: "newsFeed" */ 'tools/updateNewsFeed').then(
        exports => exports.init()
    );
}

if (document.querySelector('#userLayerModal')) {
    import(/* webpackChunkName: "userLayer" */ 'blocks/userLayer').then(exports => exports.default());
}

if (document.getElementById('master-slave-reminder-popup')) {
    import(/* webpackChunkName: "masterSlaveReminder" */ 'masterSlaveReminder/masterSlaveReminderPopup').then(
        exports => exports.init()
    );
}

if (document.querySelector('[data-userlike-conf]') && (!isMobile() || JSON.parse(document.querySelector('[data-isloggedin]').dataset.isloggedin))) {
    import(/* webpackChunkName: "userlike" */ 'tools/userlike').then(
        exports => exports.init()
    );
}

if (document.getElementById('canConnectToMasterAccount')) {
    import(/* webpackChunkName: "canConnectToMaster" */ 'tools/canConnectToMaster').then(() => undefined);
}

if (document.querySelector('[data-pa-upgrade-box]')) {
    import(/* webpackChunkName: "paUpgradeBox" */ 'tools/paUpgradeBox').then(() => undefined);
}

if (document.querySelector('[data-visibility-toggle]')) {
    import(/* webpackChunkName: "visibilityToggle" */ 'tools/visibilityToggle').then(
        exports => exports.initToggler()
    );
}

if (document.querySelector('[data-tooltip]')) {
    import(/* webpackChunkName: "tooltip" */ 'tools/tooltip').then(() => undefined);
}

const pendoInit = document.querySelector('[data-pendo-init]');
const pendoElement = document.querySelector('[data-pendo-config]');
if (pendoInit && pendoElement) {
    import(/* webpackChunkName: "pendoInit" */ 'pendo/pendoInit').then(() => undefined);
}

if (!isMobile()) {
    const cleverpushScriptElement = document.querySelector('[data-var-name="cleverpushScript"]');
    if (cleverpushScriptElement) {
        new DynamicScript(cleverpushScriptElement.dataset.var, { async: true });
    }
}

function checkForLogout() {
    const mainElement = document.querySelector('#main');
    if (mainElement) {
        mainElement.addEventListener('click', function(event) {
            if (event.target.dataset?.datalayerType === 'logout') {
                dataLayer.pushLogout();
            }
        });
    }
}

function checkForLogin() {
    const mainElement = document.querySelector('#main');
    if (mainElement) {
        const navItemLogin = mainElement.querySelector('[data-gtm="navMain__navigationItem--login"]');
        if (!navItemLogin) {
            dataLayer.pushLogin();
        }
    }
}

// close platform switch banner
const closeBanner = document.querySelector('[data-close-banner]');
const banner = document.querySelector('[data-platformSwitch-banner]');
if (closeBanner) {
    closeBanner.addEventListener('click', () => {
        banner.remove();
    });
    closeBanner.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === 13) {
            banner.remove();
        }
    });
}
