import { isHidden } from 'tools/domHelpers';
import { inView } from 'tools/inView';
/**
 *
 * @param element
 * @param threshold
 *
 * @returns {boolean}
 */
function lazyInView(element, threshold = 0) {
    let offsetElement = element;

    // data-unveil-parent is in use for triggering unveil on hidden elements (e.g. contents of tooltips).
    // The element is unveiled by the coordinates of the stated visible parent.
    if (offsetElement.matches('[data-unveil-parent]')) {
        offsetElement = element.closest(element.dataset.unveilParent);
    } else if (isHidden(element)) {
        return false;
    }

    return inView(offsetElement, true, threshold);
}

export { lazyInView };
