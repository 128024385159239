/* global console */ // eslint-disable-line no-redeclare

export const globalSettings = {
    namespaced: true,

    state: {
        hasGcs: false,
        hasKcs: false,
        hasGds: false,
        locale: '',
        selectedLanguage: '0',
        allowBrowseHistory: false,
        hrefBrowseHistory: null,
        contentOwners: [],
    },

    getters: {
        hasGcs: state => state.hasGcs,
        hasKcs: state => state.hasKcs,
        hasGds: state => state.hasGds,
        locale: state => state.locale,
        selectedLanguage: state => state.selectedLanguage,
        allowBrowseHistory: state => state.allowBrowseHistory,
        hrefBrowseHistory: state => state.hrefBrowseHistory,
        contentOwners: state => state.contentOwners || [],
    },

    actions: {
        initValues({ commit }, param) {
            commit('init', param);
        },
    },

    mutations: {
        init(state, param) {
            state.locale = param.locale;
            state.hasGcs = param.dataHasGcs;
            state.hasKcs = param.dataHasKcs;
            state.hasGds = param.dataHasGds;
            state.locale = param.locale;
            state.contentOwners = param.contentOwners;
            state.selectedLanguage = param.dataSelectedLanguage;
            state.allowBrowseHistory = param.allowBrowseHistory;
            state.hrefBrowseHistory = param.hrefBrowseHistory;
        },
    },
};
