import { getCookie } from 'tools/cookies';
import searchStore from 'search/searchStore';
import { encodeItemResultToDatalayer } from './utils/dataEncoding';
import { EVENT_DEFAULT } from './utils/dataLayerConstants';

const dataObject = {
    'event': 'search',
    'search_term': '',
    'search_filter_tags': '',
    'search_filter_content': '',
    'search_filter_data_base': '',
    'search_filter_location_focus': '',
    'search_filter_archive': '',
    'search_filter_country': '',
    'search_filter_industry': '',
    'search_filter_industry_subcategory': '',
    'search_filter_publication_date': '',
    'search_filter_regions': '',
    'search_filter_search_accuracy': '',
    'search_results_total': '',
    'search_results_statistics': '',
    'search_results_reports': '',
    'search_results_topics': '',
    'search_results_market_insights': '',
    'search_results_forecasts_surveys': '',
    'search_results_infographics': '',
    'search_results_company_insights': '',
    'search_results_ecommerce_insights': '',
    'search_results_consumer_insights': '',
    'search_sort': '',
    'search_page': '',
    'search_results_group_1': [],
    'search_results_group_2': [],
    'search_results_group_3': [],
    'search_promoted_group': [],
    'search_filter_static_session_id': '',
};

function pushToDataLayer() {
    const metrics = searchStore.getters['search/metrics'];
    const uri = window.location.search.substring(1);
    const URLSearchParams = window.URLSearchParams;
    const uparams = new URLSearchParams(uri);
    const payload = {};
    const query = uparams.get('q');
    const interval = uparams && uparams.get('interval') ? uparams.get('interval') : EVENT_DEFAULT.INTERVAL;
    const accuracy = uparams && uparams.get('accuracy') ? uparams.get('accuracy') : EVENT_DEFAULT.ACCURACY;
    const countries = uparams && uparams.get('region[]') ? uparams.get('region[]') : null;
    const region = uparams && uparams.get('isoregion') ? uparams.get('isoregion') : EVENT_DEFAULT.REGION;
    const locationFocus = uparams && uparams.get('isRegionPref') ? uparams.get('isRegionPref') : EVENT_DEFAULT.LOCATION_FOCUS;
    const category = uparams && uparams.get('category[]') ? uparams.get('category[]') : EVENT_DEFAULT.CATEGORY;
    const subCategory = uparams && uparams.get('subCategory[]') ? uparams.get('subCategory[]') : null;
    const contentType = uparams && uparams.get('tabGroup') ? uparams.get('tabGroup') : null;
    const sortMethod = uparams && uparams.get('sortMethod') ? uparams.get('sortMethod') : EVENT_DEFAULT.SORT_METHOD;
    const archive = uparams && uparams.get('idRelevance') ? parseInt(uparams.get('idRelevance')) : EVENT_DEFAULT.ARCHIVE;
    const language = uparams && uparams.get('language') ? uparams.get('language') : null;
    const page = uparams && parseInt(uparams.get('p')) || EVENT_DEFAULT.PAGE;
    const tags = getCookie('tags') ? getCookie('tags').split(',') : null;
    const staticSessionId = getCookie('STATSESSID') ? getCookie('STATSESSID') : null;

    const promotedArray = searchStore.getters['search/promotedArray'];
    const resultArray = searchStore.getters['search/resultArray'];

    const encodedResultArray = encodeItemResultToDatalayer(resultArray);
    const encodedPromotedArray = encodeItemResultToDatalayer(promotedArray);

    const specificKeys = [
        'event', 'search_term',
        'search_filter_publication_date', 'search_filter_search_accuracy',
        'search_filter_regions', 'search_filter_location_focus', 'search_filter_industry',
        'search_filter_archive', 'search_sort', 'search_page',
        'search_results_total', 'search_results_statistics',
        'search_results_reports', 'search_results_topics',
        'search_results_market_insights', 'search_results_forecasts_surveys',
        'search_results_infographics', 'search_results_company_insights',
        'search_results_ecommerce_insights', 'search_results_consumer_insights', 'search_filter_archive',
    ];

    if (countries) {
        specificKeys.push('search_filter_country');
    }
    if (subCategory) {
        specificKeys.push('search_filter_industry_subcategory');
    }
    if (contentType) {
        specificKeys.push('search_filter_content');
    }
    if (language) {
        specificKeys.push('search_filter_data_base');
    }
    if (tags && tags.length > 0) {
        specificKeys.push('search_filter_tags');
    }
    if (staticSessionId && staticSessionId.length > 0) {
        specificKeys.push('search_filter_static_session_id');
    }
    if (encodedResultArray.length > 0){
        encodedResultArray.forEach((item, index) => {
            specificKeys.push(`search_results_group_${ index + 1 }`);
        });
    }
    if (encodedPromotedArray.length > 0){
        specificKeys.push('search_promoted_group');
    }

    const customValues = {
        'event': 'search',
        'search_term': query,
        'search_filter_tags': tags,
        'search_filter_content': contentType,
        'search_filter_data_base': language,
        'search_filter_location_focus': locationFocus,
        'search_filter_archive': archive,
        'search_filter_country': countries,
        'search_filter_industry': category,
        'search_filter_industry_subcategory': subCategory,
        'search_filter_publication_date': interval,
        'search_filter_regions': region,
        'search_filter_search_accuracy': accuracy,
        'search_results_total': Number(searchStore.getters['search/totalFound']),
        'search_results_statistics': Number(metrics.pureStatistic),
        'search_results_reports': Number(metrics.report),
        'search_results_topics': Number(metrics.topic),
        'search_results_market_insights': Number(metrics.market),
        'search_results_forecasts_surveys': Number(metrics.forecast),
        'search_results_infographics': Number(metrics.infographic),
        'search_results_company_insights': Number(metrics.company),
        'search_results_ecommerce_insights': Number(metrics.ecommercedb),
        'search_results_consumer_insights': Number(metrics.gcs),
        'search_sort': sortMethod,
        'search_page': page,
        'search_results_group_1': encodedResultArray[0]? encodedResultArray[0] : '',
        'search_results_group_2': encodedResultArray[1]? encodedResultArray[1] : '',
        'search_results_group_3': encodedResultArray[2]? encodedResultArray[2] : '',
        'search_promoted_group': encodedPromotedArray[0],
        'search_filter_static_session_id': staticSessionId,
    };

    for (const key of specificKeys) {
        if (Object.prototype.hasOwnProperty.call(dataObject, key)) {
            payload[key] = Object.prototype.hasOwnProperty.call(customValues, key) ? customValues[key] : dataObject[key];
        }
    }

    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push(payload);
}

function pushToDataLayerResult(position, contentId, contentType, isPromoted) {
    const uri = window.location.search.substring(1);
    const URLSearchParams = window.URLSearchParams;
    const uparams = new URLSearchParams(uri);
    const query = uparams.get('q');
    const page = parseInt(uparams.get('p')) || 1;

    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push({
        'event': 'search_click',
        'search_term': query,
        'search_index': 'general',
        'search_position': position,
        'search_content_id': contentId,
        'search_content_type': contentType,
        'search_is_editorial_pick': isPromoted,
        'search_page': page,
    });
}

function pushToDataLayerOldFrontend() {
    const uri = window.location.search.substring(1);
    const URLSearchParams = window.URLSearchParams;
    const uparams = new URLSearchParams(uri);
    const payload = {};
    const query = uparams.get('q');
    const interval = getIntervalOld();
    const accuracy = uparams && uparams.get('accuracy') ? uparams.get('accuracy') : null;
    const countries = uparams && uparams.getAll('region[]') ? uparams.getAll('region[]') : null;
    const region = getLocationOld();
    const locationFocus = getRegionBoostOld();
    const category = getIndustryOld();
    const contentType = getContentTypeOld();
    const sortMethod = getSortOld();
    const archive = getArchiveOld() ? '1' : '0';
    const db = getDbOld();
    const countriesName = getCountriesOld(countries);

    const specificKeys = [];
    specificKeys.push('event');
    specificKeys.push('search_term');
    if (interval) {
        specificKeys.push('search_filter_publication_date');
    }
    if (accuracy) {
        specificKeys.push('search_filter_search_accuracy');
    }
    if (countriesName) {
        specificKeys.push('search_filter_country');
    }
    if (region) {
        specificKeys.push('search_filter_regions');
    }
    if (locationFocus) {
        specificKeys.push('search_filter_location_focus');
    }
    if (category) {
        specificKeys.push('search_filter_industry');
    }
    if (contentType) {
        specificKeys.push('search_filter_content');
    }
    if (sortMethod) {
        specificKeys.push('search_sort');
    }
    if (archive) {
        specificKeys.push('search_filter_archive');
    }
    if (db) {
        specificKeys.push('search_filter_data_base');
    }

    specificKeys.push('search_results_total');
    specificKeys.push('search_results_statistics');
    specificKeys.push('search_results_reports');
    specificKeys.push('search_results_topics');
    specificKeys.push('search_results_market_insights');
    specificKeys.push('search_results_forecasts_surveys');
    specificKeys.push('search_results_infographics');
    specificKeys.push('search_results_consumer_insights');

    let customValues = {};
    const countForContents = getFilterCountOld();

    customValues = {
        'event': 'search',
        'search_term': query,
        'search_filter_content': contentType,
        'search_filter_data_base': db,
        'search_filter_location_focus': locationFocus,
        'search_filter_archive': archive,
        'search_filter_country': countriesName,
        'search_filter_industry': category,
        'search_filter_publication_date': interval,
        'search_filter_regions': region,
        'search_filter_search_accuracy': accuracy,
        'search_results_total': getTotalFoundOld(),
        'search_results_statistics': countForContents[0],
        'search_results_forecasts_surveys': countForContents[1],
        'search_results_infographics': countForContents[2],
        'search_results_topics': countForContents[3],
        'search_results_consumer_insights': countForContents[4],
        'search_results_reports': countForContents[5],
        'search_results_market_insights': countForContents[6],
        'search_sort': sortMethod,
    };
    for (const key of specificKeys) {
        if (Object.prototype.hasOwnProperty.call(dataObject, key)) {
            payload[key] = Object.prototype.hasOwnProperty.call(customValues, key) ? customValues[key] : dataObject[key];
        }
    }

    window.dataLayer = window.dataLayer || {};
    window.dataLayer.push(payload);

}
function getIndustryOld() {
    const selectElement = document.querySelector('#category');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].value;

    return output;
}

function getIntervalOld() {
    const selectElement = document.querySelector('#interval');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].value;

    return output;
}

function getSortOld() {
    const selectElement = document.querySelector('#sortMethod');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].text;

    return output;
}
function getRegionBoostOld() {
    const selectElement = document.querySelector('#isRegionPref');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].value;

    return output;
}
function getDbOld() {
    const selectElement = document.querySelector('#language');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].value;

    return output;
}

function getLocationOld() {
    const selectElement = document.querySelector('#isoregion');
    if (!selectElement) {
        return null;
    }
    const output = selectElement.options[selectElement.selectedIndex].value;

    return output;
}

function getCountriesOld(arrayOfCountries) {
    const countriesName = [];

    if (arrayOfCountries && arrayOfCountries.length > 0) {
        for (const country of arrayOfCountries) {
            const selectElement = document.getElementById(`isocountry_${ country }`);
            if (!selectElement || selectElement === undefined) {
                return null;
            } else {
                countriesName.push(selectElement.value);
            }
        }

        return countriesName.join(',');
    } else {
        return null;
    }

}

function getArchiveOld() {
    const selectElement = document.querySelector('#archive');
    if (!selectElement) {
        return null;
    }
    const output = Number(selectElement.options[selectElement.selectedIndex].value);

    return !output;
}

function getTotalFoundOld() {
    const totalFoundElement = document.getElementById('gtm_search_resultsCount');
    if (totalFoundElement) {
        return Number(totalFoundElement.getAttribute('data-value'));
    } else {
        return 0;
    }
}

function getFilterCountOld() {
    const totalFoundStatistic = document.getElementById('entity__spanValue--statistics') ? Number(document.getElementById('entity__spanValue--statistics').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundForecast = document.getElementById('entity__spanValue--forecasts') ? Number(document.getElementById('entity__spanValue--forecasts').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundInfographic = document.getElementById('entity__spanValue--infos') ? Number(document.getElementById('entity__spanValue--infos').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundTopics = document.getElementById('entity__spanValue--topics') ? Number(document.getElementById('entity__spanValue--topics').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundGcs = document.getElementById('entity__spanValue--gcs') ? Number(document.getElementById('entity__spanValue--gcs').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundCMO = document.getElementById('entity__spanValue--cmo') ? Number(document.getElementById('entity__spanValue--cmo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundMMO = document.getElementById('entity__spanValue--mmo') ? Number(document.getElementById('entity__spanValue--mmo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundTMO = document.getElementById('entity__spanValue--tmo') ? Number(document.getElementById('entity__spanValue--tmo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundCO = document.getElementById('entity__spanValue--co') ? Number(document.getElementById('entity__spanValue--co').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundAMO = document.getElementById('entity__spanValue--amo') ? Number(document.getElementById('entity__spanValue--amo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundIO = document.getElementById('entity__spanValue--io') ? Number(document.getElementById('entity__spanValue--io').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundHMO = document.getElementById('entity__spanValue--hmo') ? Number(document.getElementById('entity__spanValue--hmo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundDMO = document.getElementById('entity__spanValue--dmo') ? Number(document.getElementById('entity__spanValue--dmo').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundReportA = document.getElementById('entity__spanValue--groupA') ? Number(document.getElementById('entity__spanValue--groupA').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundReportB = document.getElementById('entity__spanValue--groupB') ? Number(document.getElementById('entity__spanValue--groupB').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundReportC = document.getElementById('entity__spanValue--groupC') ? Number(document.getElementById('entity__spanValue--groupC').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundReportD = document.getElementById('entity__spanValue--groupD') ? Number(document.getElementById('entity__spanValue--groupD').getElementsByTagName('span')[0].getAttribute('data-value')) : 0;
    const totalFoundReports = totalFoundReportA + totalFoundReportB + totalFoundReportC + totalFoundReportD;
    const totalFoundMarket = totalFoundAMO + totalFoundCO + totalFoundCMO + totalFoundDMO + totalFoundHMO + totalFoundIO + totalFoundMMO + totalFoundTMO;

    return [totalFoundStatistic, totalFoundForecast, totalFoundInfographic, totalFoundTopics, totalFoundGcs, totalFoundReports, totalFoundMarket];
}

function getContentTypeOld() {
    const contents = [];

    if (checkUrlForArrayValue(['statistics-group', 'statistics'], 'statistic')) {
        contents.push(checkUrlForArrayValue(['statistics-group', 'statistics'], 'statistic'));
    }
    if (checkUrlForArrayValue(['studies-reports', 'groupD', 'dossiers', 'dossiersplus', 'branchreports', 'countryreports', 'cityreports', 'ecommercedbreports', 'xmo', 'companyreports', 'brandreports', 'toplists', 'surveys', 'groupA', 'groupB', 'groupC', 'groupD'], 'report')) {
        contents.push(checkUrlForArrayValue(['studies-reports', 'groupD', 'dossiers', 'dossiersplus', 'branchreports', 'countryreports', 'cityreports', 'ecommercedbreports', 'xmo', 'companyreports', 'brandreports', 'toplists', 'surveys', 'groupA', 'groupB', 'groupC', 'groupD'], 'report'));
    }
    if (checkUrlForArrayValue(['topics'], 'topic')) {
        contents.push(checkUrlForArrayValue(['topics'], 'topic'));
    }
    if (checkUrlForArrayValue(['infos'], 'infographic')) {
        contents.push(checkUrlForArrayValue(['infos'], 'infographic'));
    }
    if (checkUrlForArrayValue(['forecasts'], 'forecast')) {
        contents.push(checkUrlForArrayValue(['forecasts'], 'forecast'));
    }
    if (checkUrlForArrayValue(['cmo', 'mmo', 'co', 'tmo', 'amo', 'io', 'hmo', 'dmo', 'fmo'], 'market')) {
        contents.push(checkUrlForArrayValue(['cmo', 'mmo', 'co', 'tmo', 'amo', 'io', 'hmo', 'dmo', 'fmo'], 'market'));
    }
    if (checkUrlForArrayValue(['gcs'], 'gcs')) {
        contents.push(checkUrlForArrayValue(['gcs'], 'gcs'));
    }
    if (checkUrlForArrayValue(['companies'], 'company')) {
        contents.push(checkUrlForArrayValue(['companies'], 'company'));
    }
    if (checkUrlForArrayValue(['ecommercedb'], 'ecommercedb')) {
        contents.push(checkUrlForArrayValue(['ecommercedb'], 'ecommercedb'));
    }


    return contents.length > 0 ? contents.join(',') : null;
}
function checkUrlForArrayValue(array, content) {
    const uri = window.location.search.substring(1);
    const URLSearchParams = window.URLSearchParams;
    const urlParams = new URLSearchParams(uri);
    let result = null;

    for (const param of urlParams.entries()) {
        const [key, value] = param;
        if (array.includes(key) && value === '1') {
            result = content;
            break;
        }
    }

    return result;
}
export { pushToDataLayer, pushToDataLayerResult, pushToDataLayerOldFrontend } ;
