import Vue from 'vue';
import searchAutocomplete from '../components/searchAutocomplete';

if (document.getElementById('vue-top-search')) {
    new Vue({
        el: '#vue-top-search',
        render(createElement) {
            return createElement(searchAutocomplete, {
                props: {
                    inputSelector: '#q',
                },
                class: 'searchAutocompleteList--small z-index-4',
            });
        },
    });
}

if (document.getElementById('vue-hub-page-search')) {
    new Vue({
        el: '#vue-hub-page-search',
        render(createElement) {
            return createElement(searchAutocomplete, {
                props: {
                    inputSelector: '.hubSearch__input',
                },
            });
        },
    });
}

if (document.getElementById('vue-statistic-page-search')) {
    new Vue({
        el: '#vue-statistic-page-search',
        render(createElement) {
            return createElement(searchAutocomplete, {
                props: {
                    inputSelector: '.statisticSearch__input',
                },
            });
        },
    });
}

if (document.getElementById('vue-branch-page-search')) {
    new Vue({
        el: '#vue-branch-page-search',
        render(createElement) {
            return createElement(searchAutocomplete, {
                props: {
                    inputSelector: '.branchSearch__input',
                },
            });
        },
    });
}
