<template>
    <transition name="fade">
        <div
            v-if="show"
            :style="[styles, widthStyle]"
            class="autocompleteList autocompleteList--old">
            <ul class="autocompleteList__results">
                <li
                    v-for="(item, index) in axiosResult"
                    :key="item"
                    :class="{ 'autocompleteList__item--selected' : index === selectedResult }"
                    class="autocompleteList__resultsItem"
                    @click="submitForm(item)">
                    <a
                        v-if="submitSelfAction"
                        class="autocompleteList__item autocompleteList__item--linkReset"
                        v-html="highlightSearch(item, search)">
                    </a>
                    <a
                        v-else
                        class="autocompleteList__item autocompleteList__item--linkReset"
                        :href="`/statistik/suche/?q=${ encodeItem(item) }`"
                        v-html="highlightSearch(item, search)">
                    </a>
                </li>
            </ul>
        </div>
    </transition>
</template>

<script>
    import Translator from 'tools/trans';
    import axios from 'axios';
    import { pushToDataLayerOldFrontend } from 'search/dataLayer';

    export default {
        props: {
            inputSelector: { type: String, required: true },
            widthSelector: { type: String, default: null },
            selectedLanguage: { type: String, default: '0' },
            styles: { type: Object, default: () => ({}) },
            submitSelfAction: { type: Boolean, default: false },
        },
        data() {
            return {
                MAX_LENGTH: 500,
                search: '',
                axiosResult: [],
                show: false,
                selectedResult: null,
                originalSearch: '',
                placeholder: Translator.trans('HOMEPAGE_SEARCHBAR_PLACEHOLDER_2016'),
                gettingStarted: '',
                searchInput: null,
                widthStyle: {},
                requestTimeout: 250,
                requestTimeoutId: null,
            };
        },
        watch: {
            search(searchString) {
                this.searchInput.value = searchString;
            },
        },
        methods: {
            trans: Translator.trans,
            searchResult() {
                clearTimeout(this.requestTimeoutId);
                this.requestTimeoutId = setTimeout(() => {
                    if (this.search.length > 2 && (this.selectedResult === null || this.selectedResult < 0)) {
                        axios.get(`/autocomplete/?language=${ this.selectedLanguage }&term=${ this.search }`).then(response => {
                            this.axiosResult = response.data;
                            if (this.axiosResult.length > 0) {
                                this.show = true;
                            }
                            this.originalSearch = this.search;
                        });
                    }
                    this.show = false;
                }, this.requestTimeout);
            },
            submitForm(item) {
                this.searchInput.value = item;
                // push to data layer
                setTimeout(() => {
                    pushToDataLayerOldFrontend();
                }, 1000);
            },
            highlightSearch(item, search) {
                const regEx = new RegExp(search, 'gi');

                return item.replace(regEx, function(search) { return `<span class="autocompleteList__item--highlightItem">${ search }</span>`; });
            },
            encodeItem(item) {
                const str = item;
                const specialChars = {
                    '+': '%2B',
                    '&': '%26',
                    '$': '%24',
                    ',': '%2C',
                    '/': '%2F',
                    ':': '%3A',
                    ';': '%3B',
                    '=': '%3D',
                    '?': '%3F',
                    '@': '%40',
                };
                const specialToASCII = str => {
                    let res = '';
                    for (let i = 0; i < str.length; i++){
                        if (+str[i] || str[i].toLowerCase() !== str[i].toUpperCase() || str[i] === ' '){
                            res += str[i];
                            continue;
                        }
                        res += str[i].replace(str[i], specialChars[str[i]] ?? str[i]);
                    }

                    return res;
                };

                return specialToASCII(str);
            },
            selectDown() {
                if (this.selectedResult === null) {
                    this.originalSearch = this.search;
                }
                if (this.selectedResult >= this.axiosResult.length - 1 || this.selectedResult < 0) {
                    this.selectedResult = 0;
                } else {
                    this.selectedResult = this.selectedResult === null ? 0 : this.selectedResult + 1;
                }
                this.search = this.axiosResult[this.selectedResult];
            },
            selectUp() {
                if (this.selectedResult === null) {
                    this.originalSearch = this.search;
                }
                this.selectedResult = this.selectedResult === null ? this.axiosResult - 1 : this.selectedResult - 1;
                if (this.selectedResult < 0) {
                    this.search = this.originalSearch;
                    this.show = true;
                } else {
                    this.search = this.axiosResult[this.selectedResult];
                }
            },
            reset() {
                if (this.originalSearch.length > 0) {
                    this.search = this.originalSearch;
                    this.originalSearch = '';
                    this.selectedResult = null;
                }
                this.show = false;
            },
            hideAutocomplete() {
                this.show = false;
            },
        },
        created() {
            this.gettingStarted = document.querySelector('[data-gettingstartedlink]').dataset.gettingstartedlink;
        },
        mounted() {
            this.searchInput = document.querySelector(this.inputSelector);
            if (this.widthSelector) {
                this.widthStyle.width = `${ document.querySelector(this.widthSelector).offsetWidth }px`;
            }

            this.searchInput.addEventListener('input', event => {
                const searchRawInput = event.currentTarget.value;
                if (searchRawInput.length > this.MAX_LENGTH) {
                    event.currentTarget.value = searchRawInput.slice(0, this.MAX_LENGTH);

                    return;
                }
                this.search = searchRawInput;
                this.searchResult();
            });
            this.searchInput.addEventListener('keydown', event => {
                switch (event.key) {
                    case 'ArrowUp':
                        this.selectUp();
                        break;

                    case 'ArrowDown':
                        this.selectDown();
                        break;

                    case 'Escape':
                        this.reset();
                        break;

                    default:
                        break;
                }
            });
            document.body.addEventListener('click', (event) => {
                if (this.searchInput !== event.target) {
                    this.hideAutocomplete();
                }
            });
        },
    };
</script>
