function trans(text, replaceData) {
    if (!window._locale) {
        const gtmLocaleElement = document.getElementById('gtm_locale');
        window._locale = gtmLocaleElement ? gtmLocaleElement.dataset.page : 'en';
    }

    const locale = window._locale;
    let translatedText;

    if (window.stGlobals && window.stGlobals._trans) {
        if (window.stGlobals._trans[locale] && window.stGlobals._trans[locale][text]) {
            translatedText = window.stGlobals._trans[locale][text];
        } else if (window.stGlobals._trans.en && window.stGlobals._trans.en[text]) {
            translatedText = window.stGlobals._trans.en[text];
        }
    }
    if (translatedText && replaceData) {
        const transVariables = Object.keys(replaceData);
        transVariables.forEach(transVariable => {
            const replaceValue = replaceData[transVariable];
            translatedText = translatedText.replace(transVariable, replaceValue);
        });
    }

    return translatedText ? translatedText : text;
}

export default class Translator {
    static trans = trans;
}
