<template>
    <div
        v-show="visible"
        :id="id"
        role="dialog"
        aria-modal="true"
        :aria-hidden="ariaHidden"
        :data-show-count="showCount"
        :aria-labelledby="ariaLabelledBy"
        :class="classList"
        class="hidePrint"
        data-vue-modal-root>
        <Overlay
            v-if="hasOverlay"
            :overlay-shown="modalOpen"
            :can-add-hidden-class="canAddHiddenClass"
            @toggle-modal="closeModal" />
        <div
            ref="vueModal__container"
            class="vueModal__container"
            :class="containerClasses">
            <button
                ref="closeButton"
                :aria-label="trans('GLOBAL_CLOSE')"
                type="button"
                class="vueModal__close"
                :data-gtm="customCloseButtonDataGtm"
                :class="customCloseButtonClasses"
                @click="$emit('toggle-modal', 'close')">
                ✕
            </button>
            <slot class="vueModal__dialog vueModal__content"></slot>
        </div>
    </div>
</template>

<script>
    import Overlay from 'components/overlay';
    import Translator from 'tools/trans';
    import { getFocusableElements } from 'tools/getFocusableElements';

    const MODAL_TIMEOUT_MS = 300;

    export default {
        name: 'Modal',
        components: {
            Overlay,
        },
        props: {
            modalOpen: { type: Boolean, default: false },
            hasOverlay: { type: Boolean, default: true },
            allowOverlayScrolling: { type: Boolean, default: false },
            customContainerClasses: { type: Array, default: () => [] },
            customCloseButtonClasses: { type: Array, default: () => [] },
            customCloseButtonDataGtm: { type: String, default: null },
            ariaLabelledBy: { type: String, default: null },
            showCount: { type: Number, default: null },
            id: { type: String, default: null },
            classList: { type: String, default: null },
            isFocusable: { type: Boolean, default: true },
            canAddHiddenClass: { type: Boolean, default: true },
        },
        data() {
            return {
                firstCall: true,
                containerClasses: this.customContainerClasses,
                visible: this.modalOpen,
                previouslyFocusedElement: null,
            };
        },
        computed: {
            ariaHidden() {
                return (!this.visible).toString();
            },
        },
        watch: {
            modalOpen: {
                immediate: true,
                handler(fadeIn) {
                    if (!this.allowOverlayScrolling) {
                        const bodyClasses = document.body.classList;
                        if (fadeIn) {
                            if (!bodyClasses.contains('js--vueModal__overlayOpen')) {
                                bodyClasses.add('js--vueModal__overlayOpen');
                            }
                        } else {
                            if (bodyClasses.contains('js--vueModal__overlayOpen')) {
                                bodyClasses.remove('js--vueModal__overlayOpen');
                            }
                        }
                    }
                    this.toggleVisibility(fadeIn);
                },
            },
        },
        methods: {
            trans: Translator.trans,
            closeModal() {
                this.$emit('toggle-modal', 'close');
            },
            removeStringFromArray(array, string) {
                const newArray = array.slice();
                newArray.splice(newArray.indexOf(string), 1);

                return newArray;
            },
            addStringToArray(array, string) {
                const newArray = array.slice();
                if (newArray.indexOf(string) === -1) {
                    newArray.push(string);
                }

                return newArray;
            },
            toggleVisibility(fadeIn) {
                clearTimeout(this.visiblityTimeout);
                if (fadeIn) {
                    this.containerClasses = this.addStringToArray(this.containerClasses, 'active');
                    this.containerClasses = this.removeStringFromArray(this.containerClasses, 'hidden');
                    this.containerClasses = this.removeStringFromArray(this.containerClasses, 'vueModal__container--fadeOut');
                    this.visible = fadeIn;
                    this.$nextTick(() => {
                        this.setFocusIntoModal();
                        // add padding to prevent rendering glitch if container height is uneven
                        const container = this.$refs.vueModal__container;
                        if (container && container.offsetHeight % 2 !== 0) {
                            const paddingBottom = parseFloat(window.getComputedStyle(container).getPropertyValue('padding-bottom'));
                            container.style.setProperty('padding-bottom', `${ paddingBottom + 1 }px`, 'important');
                        }
                        this.$emit('opened');
                    });
                } else {
                    this.containerClasses = this.addStringToArray(this.containerClasses, 'vueModal__container--fadeOut');
                    if (this.previouslyFocusedElement) {
                        this.previouslyFocusedElement.focus();
                    }
                    this.visiblityTimeout = setTimeout(() => {
                        this.visible = fadeIn;
                        this.containerClasses = this.removeStringFromArray(this.containerClasses, 'vueModal__container--fadeOut');
                        this.containerClasses = this.addStringToArray(this.containerClasses, 'hidden');
                        this.$emit('closed');
                    }, MODAL_TIMEOUT_MS);
                }
            },
            findFirstFocusableElement(root) {
                return getFocusableElements({ parent: root })[0];
            },
            findLastFocusableElement(root) {
                const focusableElements = getFocusableElements({ parent: root });

                return focusableElements[focusableElements.length - 1];
            },
            trapFocus() {
                const modalContainer = this.$refs.vueModal__container;

                const firstFocusableElement = this.findFirstFocusableElement(modalContainer);
                const lastFocusableElement = this.findLastFocusableElement(modalContainer);

                const TAB_KEYCODE = 9;

                if (this.visible) {
                    modalContainer.addEventListener('keydown', event => {
                        const isTabPressed = event.key === 'Tab' || event.keyCode === TAB_KEYCODE;

                        if (!isTabPressed) {
                            return;
                        }

                        // if shift key pressed for shift + tab combination
                        if (event.shiftKey) {
                            if (document.activeElement === firstFocusableElement) {
                                // add focus for the last focusable element
                                lastFocusableElement.focus();
                                event.preventDefault();
                            }
                            // if tab key is pressed
                        } else {
                            // if focused has reached to last focusable element
                            // then focus first focusable element after pressing tab
                            if (document.activeElement === lastFocusableElement) {
                                // add focus for the first focusable element
                                firstFocusableElement.focus();
                                event.preventDefault();
                            }
                        }
                    });

                    // move focus back to the first element after losing focus to the body element by clicking somewhere inside the modal
                    // clicking on focusable elements will not trigger this
                    modalContainer.addEventListener('click', () => {
                        if (document.activeElement === document.body) {
                            // add focus for the first focusable element
                            firstFocusableElement.focus();
                        }
                    });

                    firstFocusableElement.focus();
                }
            },
            setFocusIntoModal() {
                if (!this.isFocusable) {
                    return;
                }
                this.trapFocus();
            },
        },
        mounted() {
            if (!this.hasOverlay) {
                window.addEventListener('keyup', event => {
                    if (this.visible && (event.key === 'Escape' || event.key === 'Esc')) {
                        this.$emit('toggle-modal', 'close');
                    }
                });
            }
        },
    };
</script>
