function getCookie(name) {
    const nameEQ = `${ name }=`;
    const ca = document.cookie.split(';');
    for (let i=0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
        if (c.indexOf(nameEQ) === 0) {
            let cookie = c.substring(nameEQ.length, c.length);
            cookie = cookie === 'true' ? true : cookie;
            cookie = cookie === 'false' ? false : cookie;

            return cookie;
        }
    }

    return null;
}

function createCookie(name, value, days = 10, domain = null) {
    console.warn('Deprecated: use SafeCookie to write production cookoies!');
    let expires;
    const pathProp = '; path=/';
    const domainProp = domain ? `;domain=${ domain }` : '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${ date.toGMTString() }`;
    } else {
        expires = '';
    }
    document.cookie = `${ name }=${ value }${ expires }${ pathProp }${ domainProp }; secure`;
}

function deleteCookie(name, path = '/', domainParam = null) {
    if (getCookie(name)) {
        const domain = currentDomain(domainParam);
        const pathProp = path ? `;path=${ path }` : '';
        const domainProp = domain ? `;domain=${ domain }` : '';
        document.cookie = `${ name }=${ pathProp }${ domainProp };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
}

function currentDomain(domain= null) {
    const defaultDomain = window.stGlobals.env === 'dev' ? '.statista.test' : '.statista.com';

    return domain ? domain : defaultDomain;
}

const cookies = {
    getCookie,
    createCookie,
    deleteCookie,
    currentDomain,
};

export { cookies as default, getCookie, createCookie, deleteCookie, currentDomain };
