var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "vue-modals" } },
    _vm._l(_vm.loadedModals, function (modal, index) {
      return _c(
        "modal",
        {
          key: modal.id + index,
          attrs: {
            id: modal.id,
            "class-list": modal.classList,
            "modal-open": modal.open,
            "has-overlay": modal.hasOverlay,
            "allow-overlay-scrolling": modal.allowOverlayScrolling,
            "custom-container-classes": modal.containerClasses,
            "custom-close-button-classes": modal.closeButtonClasses,
            "aria-labelled-by": modal.ariaLabelledBy,
            "show-count": modal.displayAllowedSettings.showCount
              ? modal.displayAllowedSettings.showCount
              : null,
          },
          on: {
            "toggle-modal": function ($event) {
              return _vm.toggleModal({
                id: modal.id,
                state: $event ? $event : "toggle",
              })
            },
            opened: function ($event) {
              return _vm.runCallBack(modal.afterShow)
            },
            closed: function ($event) {
              return _vm.runCallBack(modal.afterClose)
            },
          },
        },
        [_c(modal.id, { tag: "component" })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }