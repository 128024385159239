<template>
    <div id="vue-modals">
        <modal
            v-for="(modal, index) in loadedModals"
            :id="modal.id"
            :key="modal.id + index"
            :class-list="modal.classList"
            :modal-open="modal.open"
            :has-overlay="modal.hasOverlay"
            :allow-overlay-scrolling="modal.allowOverlayScrolling"
            :custom-container-classes="modal.containerClasses"
            :custom-close-button-classes="modal.closeButtonClasses"
            :aria-labelled-by="modal.ariaLabelledBy"
            :show-count="modal.displayAllowedSettings.showCount ? modal.displayAllowedSettings.showCount : null"
            @toggle-modal="toggleModal({ id: modal.id, state: $event ? $event : 'toggle' })"
            @opened="runCallBack(modal.afterShow)"
            @closed="runCallBack(modal.afterClose)">
            <component :is="modal.id" />
        </modal>
    </div>
</template>

<script>
    import modal from './modal';

    export default {
        components: {
            'modal': modal,
        },
        props: {
            modals: { type: Array, required: true },
        },
        data() {
            return {
                loadedModals: this.modals,
            };
        },
        methods: {
            toggleModal(options = {}) {
                const modalIndex = this.getModalIndex(options.id);
                const modal = this.loadedModals[modalIndex];

                if (!modal) {
                    throw new Error(`Modal with id "${ options.id }" not found.`);
                }
                if (typeof options.beforeClose === 'function') {
                    modal.beforeClose = options.beforeClose;
                }
                if (typeof options.afterClose === 'function') {
                    modal.afterClose = options.afterClose;
                }
                if (typeof options.beforeShow === 'function') {
                    modal.beforeShow = options.beforeShow;
                }
                if (typeof options.afterShow === 'function') {
                    modal.afterShow = options.afterShow;
                }

                const open = options.state === 'toggle' ? !modal.open : options.state === 'open';

                if (open) {
                    this.runCallBack(modal.beforeShow);
                    modal.open = true;
                } else {
                    this.runCallBack(modal.beforeClose);
                    modal.open = false;
                }

                this.$set(this.loadedModals, modalIndex, modal);
            },
            getModalIndex(id) {
                const modal = this.loadedModals.find(modal => modal.id === id);

                return this.loadedModals.indexOf(modal);
            },
            runCallBack(callBack) {
                if (typeof callBack === 'function') {
                    callBack();
                }
            },
        },
        created() {
            this.$root.$on('toggleModal', this.toggleModal);
        },
    };
</script>
