import replaceArray from './replaceArray';
import { stripSlashes } from 'tools/stripSlashes';

export default function(string) {
    string = string.trim();
    string = string.toLowerCase();

    try {
        string = decodeURI(string);
    } catch (error) {
        throw new Error(`Uri Encode Error: malformed URI sequence: ${ string }`);
    }

    string = replaceArray(string, orig, repl);
    string = stripSlashes(string);
    string = encodeURIComponent(string);

    return string;
}

const orig = [
    // no quotes
    "'", '"',
    // replace german umlauts
    'ö', 'Ö', 'ä', 'Ä', 'ü', 'Ü', 'ß',
    // replace latin special characters
    'ñ', 'ú', 'ù', 'Ú', 'Ù', 'ó', 'Ó', 'ô', 'Ô', 'á', 'à', 'å', 'â', 'Â', 'Á', 'À', 'Å', 'é', 'è', 'ê', 'É', 'È', 'Ê', 'ë', 'ç', 'í', 'Í', 'î', 'Î',
    // replace special characters
    '²', '³', '’', '´', '`', ' ?', ' !', ' .', '!', '?', '¿', '=', ' & ', '&', ',', '.', '% ', '« ', ' »', '«', '»', "'",
    // replace special characters
    '–', ' ', '/', '(', ')', ':', '#', '$', '€', '+',
];

const repl = [
    // no quotes
    '', '',
    // replace german umlauts
    'oe', 'oe', 'ae', 'ae', 'ue', 'ee', 'ss',
    // replace latin special characters
    'n', 'u', 'u', 'u', 'u', 'o', 'o', 'o', 'o', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'c', 'i', 'i', 'i', 'i',
    // replace special characters
    '2', '3', '', '', '', '', '', '', '', '', '', '-', '-', '-', '', '', '-', '', '_', '', '', '-',
    // replace special characters
    '-', '-', '-', '-', '-', '_', '-', 'dollar', 'euro', '-',
];
