function inView(element, allowPartialInView = false, offsetTop = 0) {
    if (!element) {
        return false;
    }
    const bounding = element.getBoundingClientRect();
    const clientHeight = document.body.clientHeight;
    const clientWidth = document.body.clientWidth;

    if (allowPartialInView) {
        return (bounding.top >= offsetTop - element.offsetHeight &&
            bounding.left >= -element.offsetWidth &&
            bounding.bottom <= clientHeight + element.offsetHeight &&
            bounding.right <= clientWidth + element.offsetWidth);
    }

    return (bounding.top >= -offsetTop &&
            bounding.left >= 0 &&
            bounding.bottom <= clientHeight &&
            bounding.right <= clientWidth);
}

export { inView };
