// Equivalent to php stripslashes() function

function stripSlashes(str) {
    return (`${ str }`).replace(/\\(.?)/g, function(s, n1) {
        switch (n1) {
            case '\\':
                return '\\';
            case '0':
                return '\u0000';
            case '':
                return '';
            default:
                return n1;
        }
    });
}

export { stripSlashes };
