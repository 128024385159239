const errorBox = document.getElementById('errorbox');
const route = window.stGlobals.currentPath;

if (route.includes('registerPremiumStepTwo') && errorBox) {
    const payPmentSelect = document.querySelector('select[name*="idPaymentType"]');
    const currentPaymentType = payPmentSelect ? payPmentSelect.querySelector('option:checked').value : '';
    const currentErrorMsg = errorBox.innerText;
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        'event': 'purchaseError',
        'funnelType': 'premium',
        'paymentOption': currentPaymentType,
        'errorName': 'purchaseError',
        'errorMessage': currentErrorMsg,
    });
}
