var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "hidePrint",
      class: _vm.classList,
      attrs: {
        id: _vm.id,
        role: "dialog",
        "aria-modal": "true",
        "aria-hidden": _vm.ariaHidden,
        "data-show-count": _vm.showCount,
        "aria-labelledby": _vm.ariaLabelledBy,
        "data-vue-modal-root": "",
      },
    },
    [
      _vm.hasOverlay
        ? _c("Overlay", {
            attrs: {
              "overlay-shown": _vm.modalOpen,
              "can-add-hidden-class": _vm.canAddHiddenClass,
            },
            on: { "toggle-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "vueModal__container",
          staticClass: "vueModal__container",
          class: _vm.containerClasses,
        },
        [
          _c(
            "button",
            {
              ref: "closeButton",
              staticClass: "vueModal__close",
              class: _vm.customCloseButtonClasses,
              attrs: {
                "aria-label": _vm.trans("GLOBAL_CLOSE"),
                type: "button",
                "data-gtm": _vm.customCloseButtonDataGtm,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle-modal", "close")
                },
              },
            },
            [_vm._v("\n            ✕\n        ")]
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }