import { isMobile } from './isMobile';

if (isMobile()) {
    import(/* webpackChunkName: "navigationMobile" */ 'tools/navigationMobile').then(
        exports => exports.init()
    );
} else {
    import(/* webpackChunkName: "navigationDesktop" */ 'tools/navigationDesktop').then(
        exports => exports.init()
    );
}
