import Vue from 'vue';
import Vuex from 'vuex';

import { globalSettings } from './store/globalSettings.module';
import { search } from './store/search.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        globalSettings,
        search,
    },
    getters: {
        query: state => state.searchQueryParameters && state.searchQueryParameters.q,
        language: state => (state.searchQueryParameters && state.searchQueryParameters.language) || '1',
        searchQueryParameters: state => state.searchQueryParameters,
    },
    state: {
        text: 'initial text',
        allFiltersDisabled: false,
        singleFilterChecked: null,
        lastExclusiveFilter: '',
        formChangedTimestamp: Math.round(new Date() / 1000),
        searchQueryParameters: {},
    },
    actions: {
        filterChangeEvent({ commit, state }) {
            const newTimestamp = Math.round(new Date() / 1000);

            if (state.formChangedTimestamp !== newTimestamp) {
                commit('updateChangeTimestamp', newTimestamp);
                const event = document.createEvent('HTMLEvents');
                event.initEvent('change', true, true);
                document.getElementById('sortMethod').dispatchEvent(event);
            }
        },
        setHistory({ dispatch }, value) {
            dispatch('search/setHistory', value, { root: true });
        },
    },
    mutations: {
        allFiltersDisabled(state, enabled) {
            state.allFiltersDisabled = enabled;
        },
        setSingleFilter(state, filter) {
            state.singleFilterChecked = filter;
        },
        setExclusive(state, filter) {
            state.lastExclusiveFilter = filter;
        },
        unsetLastExclusive(state) {
            state.lastExclusiveFilter = '';
        },
        updateChangeTimestamp(state, timestamp) {
            state.formChangedTimestamp = timestamp;
        },
        initSearchQueryParameters(state) {
            const queryParam = {};
            location.search.substr(1).split('&').forEach(function(item) {
                queryParam[item.split('=')[0]] = item.split('=')[1];
            });
            state.searchQueryParameters = queryParam;
        },
    },
});
