import { displayAllowed } from '../tools/displayAllowed';
import modalManager from 'components/modalManager';
import Vue from 'vue';
import { LazyLoader } from './lazyLoader/lazyLoader';

// Modal utility for replacing the jQuery/Bootstrap modal, based on vue.js

// Example
// -------
// <div data-vue-modal
//     id="testModal"
//     class="testModal__root"
//     data-modal-options='{"containerClasses": ["padding-all-0"], "closeButtonClasses": ["text-color-s--white"]}'
//     aria-labelledby="modal__headline">
//     <div class="vueModal__dialog">
//         <h1 id="modal__headline">Test headline</h1>
//         <a href="/statistic/1234" data-close-modal>Go to statistic and close modal</a>
//     </div>
// </div>

// Attributes
// ----------
// data-vue-modal: Used for initializing the modal. IDs and Classes are automatically passed to the modals root element.
// data-modal-options: Configuration object with the following options:
//     checkDisplayAllowed:    check if modal should be enabled (by displayAllowed.js),                   default: false
//     displayAllowedSettings: configuration object for displayAllowed.js,                                default: false
//     open:                   sets if modal should be initially opened,                                  default: false
//     containerClasses:       adds classes to the vueModal__container element,                           default: []
//     closeButtonClasses:     adds classes to the vueModal__close element,                               default: []

// Methods and event attributes
// ----------------------------
// window.toggleModal(element|modalName|modalId, state):
//   This method toggles an initialized modal. The second parameter takes the intended state ('open' or 'close'),
//   but can also be omitted for inverting the current state.
// window.initModalTogglers():
//   This method initializes new modal togglers, so ajax-loaded content can open and close modals via data-open-modal
//   and data-close-modal too. This is not implemented for initializing new modal elements because it's not yet needed.
// data-open-modal="selector":
//   This attribute can be added to any element in the DOM to open a modal, specified by a CSS selector.
//   A valid CSS selector as attribute value is required.
// data-close-modal="selector":
//   This attribute can be added to any element in the DOM to close a modal, specified by a CSS selector.
//   If the attribute is placed inside a modal element, the selector can be ommited for closing the surrounding modal.

function toggleModal(element, state = 'toggle', callbacks = { beforeShow: () => undefined, afterShow: () => undefined, beforeClose: () => undefined, afterClose: () => undefined }) {
    const modalId = typeof element.getAttribute === 'function' ? element.getAttribute('id') : element;
    window.modalManager.$emit('toggleModal', { id: modalId, state: state, beforeShow: callbacks.beforeShow, afterShow: callbacks.afterShow, beforeClose: callbacks.beforeClose, afterClose: callbacks.afterClose });
}

function initModalTogglers() {
    document.addEventListener('click', event => {
        const modalOpener = event.target.closest('[data-open-modal]');
        if (modalOpener) {
            const openTargetId = modalOpener.dataset.openModal;
            toggleModal(openTargetId.replace('#', ''), 'open');
            const lazy = new LazyLoader('img.lazy');
            lazy.init();
        }

        const modalCloser = event.target.closest('[data-close-modal]');
        if (modalCloser) {
            let closeTargetId = modalCloser.dataset.closeModal;
            if (!closeTargetId) {
                closeTargetId = modalCloser.closest('[data-vue-modal-root]').getAttribute('id');
            }
            toggleModal(closeTargetId.replace('#', ''), 'close');
        }
    });
}

const vuemodals = [];
const modals = document.querySelectorAll('[data-vue-modal]');
if (modals.length) {
    window.toggleModal = toggleModal;
    modals.forEach(modalWrapper => {
        const optionsData = modalWrapper.dataset.modalOptions ? JSON.parse(modalWrapper.dataset.modalOptions) : {};
        const options = {
            allowOverlayScrolling: typeof optionsData.allowOverlayScrolling !== 'undefined' ? optionsData.allowOverlayScrolling : false,
            countUpOnShow: typeof optionsData.countUpOnShow !== 'undefined' ? optionsData.countUpOnShow : false,
            checkDisplayAllowed: typeof optionsData.checkDisplayAllowed !== 'undefined' ? optionsData.checkDisplayAllowed : false,
            hasOverlay: typeof optionsData.hasOverlay !== 'undefined' ? optionsData.hasOverlay : true,
            displayAllowedSettings: typeof optionsData.displayAllowedSettings !== 'undefined' ? optionsData.displayAllowedSettings : {},
            open: typeof optionsData.open !== 'undefined' ? optionsData.open : false,
            containerClasses: typeof optionsData.containerClasses !== 'undefined' ? optionsData.containerClasses : [],
            closeButtonClasses: typeof optionsData.closeButtonClasses !== 'undefined' ? optionsData.closeButtonClasses : [],
            ariaLabelledBy: modalWrapper.getAttribute('aria-labelledby') ? modalWrapper.getAttribute('aria-labelledby') : null,
            id: modalWrapper.getAttribute('id') ? modalWrapper.getAttribute('id') : null,
            classList: modalWrapper.getAttribute('class') ? modalWrapper.getAttribute('class') : null,
        };

        const isEnabled = options.checkDisplayAllowed ? displayAllowed(modalWrapper, options.displayAllowedSettings) : true;
        if (isEnabled) {
            registerVueComponentFromHTML(modalWrapper.innerHTML, options.id);
            vuemodals.push(options);
            modalWrapper.remove();
        }
    });

    initModalManager();
    initModalTogglers();
}

function registerVueComponentFromHTML(html, name) {
    Vue.component(name, {
        name: name,
        template: html,
    });
}

function initModalManager() {
    window.modalManager = new Vue({
        el: '#vue-modals',
        render(createElement) {
            return createElement(modalManager, {
                props: {
                    modals: vuemodals,
                },
            });
        },
    });
}

export { toggleModal, initModalTogglers };
