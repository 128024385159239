/**
 * DynamicScript
 *
 * Loads a script and runs callback when finished.
 * Only runs the callback if script tag already exists.
 */
class DynamicScript {
    constructor(src, options = {}, callback = null) {
        this.src = src;
        this.id = typeof options.id !== 'undefined' ? options.id : null;
        this.async = typeof options.async !== 'undefined' ? options.async : null;
        this.callback = callback;
        this.existingScript = document.querySelector(`[src="${ src }"]`);
        this.scriptElement = null;
        this.init();
    }

    /**
     * @returns void
     */
    init() {
        if (this.scriptExists === false) {
            this.initNewScript();
        } else if (this.scriptExists && this.callback) {
            this.initOnExistingScript();
        }
    }

    /**
     * @returns {boolean}
     */
    get scriptExists() {
        return this.existingScript !== null;
    }

    /**
     * @returns void
     */
    initNewScript() {
        this.scriptElement = this.createScriptElement(this.src, this.async, this.id);
        document.head.appendChild(this.scriptElement);
        this.setIsLoadingTag(this.src);

        this.scriptElement = this.addLoadEvent(this.scriptElement, () => {
            this.setFinishedLoadingTag(this.src);
            if (this.callback) {
                this.callback();
            }
        });
    }

    /**
     * @returns void
     */
    initOnExistingScript() {
        if (this.isLoading(this.src) === true) {
            this.scriptElement = this.addLoadEvent(this.existingScript, () => {
                this.callback();
            });
        } else {
            this.callback();
        }
    }

    /**
     * @param src
     * @param async
     * @param id
     *
     * @returns {HTMLScriptElement}
     */
    createScriptElement(src, async, id) {
        const scriptElement = document.createElement('script');
        scriptElement.async = async ? async : scriptElement.async;
        scriptElement.id = id ? id : scriptElement.id;
        scriptElement.src = src;

        return scriptElement;
    }

    /**
     * @param url
     */
    setIsLoadingTag(url) {
        const metaTag = document.createElement('meta');
        metaTag.name = 'dynamic_loading';
        metaTag.content = url;
        document.head.appendChild(metaTag);
    }

    /**
     * @param url
     *
     * @returns {boolean}
     */
    isLoading(url) {
        return document.querySelector(`meta[content="${ url }"]`) !== null;
    }

    /**
     * @param url
     */
    setFinishedLoadingTag(url) {
        const metaTag = document.querySelector(`meta[content="${ url }"]`);
        if (metaTag) {
            document.head.removeChild(metaTag);
        }
    }

    /**
     * @param element
     * @param callback
     *
     * @returns {*}
     */
    addLoadEvent(element, callback) {
        const oldCallback = element.onload;
        if (typeof oldCallback === 'function') {
            element.onload = () => {
                oldCallback();
                callback();
            };
        } else if (typeof callback === 'function') {
            element.onload = callback;
        }

        return element;
    }
}

export { DynamicScript };
