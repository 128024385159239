import Vue from 'vue';
import notificationManager from 'components/notificationManager';
import { DomReady } from 'tools/domready';

const notifications = new Vue({
    el: '#vue-notifications',
    render(createElement) {
        return createElement(notificationManager);
    },
});

DomReady(() => {
    document.querySelectorAll('[data-notification]').forEach(item => {
        item.addEventListener('click', event => {
            const notification = JSON.parse(event.currentTarget.dataset.notification);
            addMessage(notification);
        });
    });
});

function addMessage(messageObject) {
    notifications.$emit('message', messageObject);
}

export { addMessage };
