function getSiblings(element) {
    return Array.prototype.filter.call(element.parentNode.children, sibling => sibling !== element);
}

function getArray(iterable) {
    return Array.prototype.slice.call(iterable);
}

function getOffsets(element) {
    const rect = element.getBoundingClientRect();

    return {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset,
        right: rect.right + window.pageXOffset,
    };
}

function outerWidth(element) {
    let width = element.offsetWidth;
    const style = getComputedStyle(element);

    width += parseInt(style.marginLeft) + parseInt(style.marginRight);

    return width;
}

function outerHeight(element) {
    return element.offsetHeight;
}

function hasClasses(element, classes) {
    const classesList = classes.trim().split(' ');
    for (let i = 0, length = classesList.length; i < length; i++) {
        if (!element.classList.contains(classesList[i])) {
            return false;
        }
    }

    return true;
}

function addClasses(element, classes) {
    const classesList = classes.trim().split(' ');
    classesList.forEach(className => {
        element.classList.add(className);
    });
}

function removeClasses(element, classes) {
    const classesList = classes.trim().split(' ');
    classesList.forEach(className => {
        element.classList.remove(className);
    });
}

function isHidden(elem) {
    return elem.offsetWidth === 0 && elem.offsetHeight === 0;
}

function outerSize(element) {
    return {
        width: outerWidth(element),
        height: outerHeight(element),
    };
}

function getJsonData(selector, dataProperty) {
    const element = document.querySelector(selector);

    if (element && element.dataset[dataProperty]) {
        return JSON.parse(element.dataset[dataProperty]);
    }

    return null;
}

const DISPLAY_CACHE_KEY = 'scDisplay';

function displayHide(element, beforeHide = () => undefined) {
    if (!element) {return;}

    beforeHide(element);
    element.dataset[DISPLAY_CACHE_KEY] = getComputedStyle(element).display;
    element.style.display = 'none';

    return element;
}

function displayShow(element, afterShow = () => undefined) {
    if (!element) {return;}

    element.style.display = element.dataset[DISPLAY_CACHE_KEY] || 'block';
    delete element.dataset[DISPLAY_CACHE_KEY];
    afterShow(element);

    return element;
}

function displayToggle(element, options = {}) {
    const visible = getComputedStyle(element).display !== 'none';

    if (visible) {
        displayHide(element, options.beforeHide);
    } else {
        displayShow(element, options.afterShow);
    }
}

export {
    getSiblings,
    getOffsets,
    outerWidth,
    outerHeight,
    outerSize,
    hasClasses,
    addClasses,
    removeClasses,
    getArray,
    isHidden,
    getJsonData,
    displayToggle,
    displayHide,
    displayShow,
};
