const route = window.stGlobals.currentPath;
const idPaymentElement = document.querySelector('select[name*="idPaymentType"]');

if (route.includes('registerPremiumStepTwo') && idPaymentElement) {
    window.dataLayer = window.dataLayer || [];

    document.querySelector('select[name*="idPaymentType"]').addEventListener('change', event => {
        const paymentTypeId = event.currentTarget.querySelector('option:checked').value;

        window.dataLayer.push({
            'event': 'checkoutOptionStep1',
            'ecommerce': {
                'checkout_option': {
                    'actionField': {
                        'step': 1,
                        'option': paymentTypeId,
                    },
                },
            },
        });
    });
}
