const index$2 = function isMergeableObject(value) {
    return isNonNullObject(value) && isNotSpecial(value);
};

function isNonNullObject(value) {
    return !!value && typeof value === 'object';
}

function isNotSpecial(value) {
    const stringValue = Object.prototype.toString.call(value);

    return stringValue !== '[object RegExp]' &&
        stringValue !== '[object Date]';
}

function emptyTarget(val) {
    return Array.isArray(val) ? [] : {};
}

function cloneIfNecessary(value, optionsArgument) {
    const clone = optionsArgument && optionsArgument.clone === true;

    return (clone && index$2(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value;
}

function defaultArrayMerge(target, source, optionsArgument) {
    const destination = target.slice();
    source.forEach(function(e, i) {
        if (typeof destination[i] === 'undefined') {
            destination[i] = cloneIfNecessary(e, optionsArgument);
        } else if (index$2(e)) {
            destination[i] = deepmerge(target[i], e, optionsArgument);
        } else if (target.indexOf(e) === -1) {
            destination.push(cloneIfNecessary(e, optionsArgument));
        }
    });

    return destination;
}

function mergeObject(target, source, optionsArgument) {
    const destination = {};
    if (index$2(target)) {
        Object.keys(target).forEach(function(key) {
            destination[key] = cloneIfNecessary(target[key], optionsArgument);
        });
    }
    Object.keys(source).forEach(function(key) {
        if (!index$2(source[key]) || !target[key]) {
            destination[key] = cloneIfNecessary(source[key], optionsArgument);
        } else {
            destination[key] = deepmerge(target[key], source[key], optionsArgument);
        }
    });

    return destination;
}

function deepmerge(target, source, optionsArgument) {
    const sourceIsArray = Array.isArray(source);
    const targetIsArray = Array.isArray(target);
    const options = optionsArgument || {
        arrayMerge: defaultArrayMerge,
    };
    const sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;

    if (!sourceAndTargetTypesMatch) {
        return cloneIfNecessary(source, optionsArgument);
    } else if (sourceIsArray) {
        const arrayMerge = options.arrayMerge || defaultArrayMerge;

        return arrayMerge(target, source, optionsArgument);
    } else {
        return mergeObject(target, source, optionsArgument);
    }
}

deepmerge.all = function deepmergeAll(array, optionsArgument) {
    if (!Array.isArray(array) || array.length < 2) {
        throw new Error('first argument should be an array with at least two elements');
    }

    // we are sure there are at least 2 values, so it is safe to have no initial value
    return array.reduce(function(prev, next) {
        return deepmerge(prev, next, optionsArgument);
    });
};

export { deepmerge, deepmerge as default };
