export const FOCUSABLE_ELEMENTS_SELECTOR = `
    a:not([disabled]),
    button:not([disabled]),
    input:not([disabled]),
    textarea:not([disabled]),
    select:not([disabled]),
    details,
    [tabindex="0"]:not([disabled])
`;

const DEFAULT_OPTIONS = {
    parent: document,
    selector: FOCUSABLE_ELEMENTS_SELECTOR,
    displayedOnly: true,
};

const isElementDisplayed = (element) => {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

export const getFocusableElements = (options = {}) => {
    options = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const focusableElementsNodeList = options.parent?.querySelectorAll(options.selector);
    const focusableElements = [];

    for (let i = 0; i < focusableElementsNodeList.length; i += 1) {
        const addToResults = !options.displayedOnly
            || options.displayedOnly && isElementDisplayed(focusableElementsNodeList[i]);
        if (addToResults) {
            focusableElements.push(focusableElementsNodeList[i]);
        }
    }

    return focusableElements;
};
