import { initAccordion } from './accordion';
import './tooltipbubble';
import { LazyLoader, UNVEIL_EVENT } from './lazyLoader/lazyLoader';
import { getOffsets, displayToggle, displayHide } from 'tools/domHelpers';
import { DomReady } from 'tools/domready';

/*
 * Misc Functions
 *
 * A set of functions that are used all over the page, i.e. Tabs, Hide/Show, etc.
 * This file will be included once in the base-file
 *
 */
function miscInit() {
    initAccordion();

    DomReady(() => {
        const skyScraperElement = document.querySelector('#skyscraper');
        const SKYSCRAPER_STICKY_OFFSET = 6;
        if (skyScraperElement) {
            const skyscraperPositionTop = getOffsets(skyScraperElement).top;
            window.addEventListener('scroll', () => {
                if (window.scrollY > skyscraperPositionTop - SKYSCRAPER_STICKY_OFFSET) {
                    skyScraperElement.classList.add('skyscraper--sticky');
                } else {
                    skyScraperElement.classList.remove('skyscraper--sticky');
                }
            });
        }

        // Fallback for missing images
        const onErrorImages = document.querySelectorAll('img[data-hideonerror]');
        if (onErrorImages.length) {
            onErrorImages.forEach(image => image.addEventListener('error', event => {
                event.currentTarget.style.display = 'none';
            }));
        }

        /*
         * Show / Hide Buttons
         */
        document.body.addEventListener('click', event => {
            const hideElement = event.target.closest('[data-hide]');
            if (hideElement) {
                toggleHide(hideElement);
            }
        });

        function toggleHide(element) {
            const hideElement = document.querySelector(`#${ element.dataset.hide }`);
            const textSpan = element.querySelector('span');
            const textNew = element.dataset.toggle;
            if (textSpan) {
                element.dataset.toggle = textSpan.textContent;
                textSpan.textContent = textNew;
            }

            const newHtml = element.dataset.togglehtml;
            if (newHtml) {
                element.dataset.togglehtml = element.innerHTML;
                element.innerHTML = newHtml;
            }

            // Toggle Icon
            const icon = element.querySelector('i.iconColl');
            if (icon) {
                if (icon.classList.contains('up')) {
                    icon.classList.remove('up');
                    icon.classList.add('down');
                } else {
                    icon.classList.remove('down');
                    icon.classList.add('up');
                }
            }

            if (element.classList.contains('isOpen')) {
                element.classList.remove('isOpen');
            } else {
                element.classList.add('isOpen');
            }

            if (element.getAttribute('aria-expanded') === 'true') {
                element.setAttribute('aria-expanded', 'false');
            } else {
                element.setAttribute('aria-expanded', 'true');
            }
            displayToggle(hideElement);
        }

        // Close Alerts and Ads
        document.body.addEventListener('click', event => {
            const closeButton = event.target.closest('.close, [data-close]');
            if (closeButton) {
                displayHide(closeButton.closest('.alert'));
                displayHide(closeButton.closest('.note'));
            }
        });

        // LazyLoading Statistic Thumbnails / if tabbed is opened
        function unveilLazy() {
            const unveilElements = document.querySelectorAll('[data-hide], .tabs li');
            unveilElements.forEach(element => {
                element.addEventListener('click', event => {
                    const unveilTarget = event.currentTarget.dataset.hide ? event.currentTarget.dataset.hide : event.currentTarget.getAttribute('rel');
                    dispatchUnveilEvent(`#${ unveilTarget }`);
                });
            });

            // unveil on tooltip mouseover
            const lazyTooltips = document.querySelectorAll('[data-tooltip]');
            if (lazyTooltips.length) {
                lazyTooltips.forEach(element => {
                    const tooltipSettings = JSON.parse(element.dataset.tooltipSettings || '{}');
                    const tooltipEventType = tooltipSettings.openOnClick ? 'click' : 'mouseover';

                    element.addEventListener(tooltipEventType, () => {
                        if (tooltipSettings.target) {
                            dispatchUnveilEvent(tooltipSettings.target);
                        }
                    });
                });
            }

            function dispatchUnveilEvent(unveilTarget) {
                const imagesToShow = document.querySelectorAll(`${ unveilTarget } img.lazy`);
                if (imagesToShow.length) {
                    imagesToShow.forEach(imageToShow => {
                        imageToShow.dispatchEvent(UNVEIL_EVENT);
                    });
                }
            }
        }
        unveilLazy();

        const lazy = new LazyLoader('img.lazy, img.lazyOnShow, iframe.lazy');
        lazy.callback = element => {
            element.addEventListener('load', event => {
                const element = event.currentTarget;
                if (element.classList.contains('tn')) {
                    element.setAttribute('width', 100);
                    element.setAttribute('height', 71);
                } else {
                    if (typeof element.dataset.srcwidth !== 'undefined') {
                        element.style.width = `${ element.dataset.srcwidth }px`;
                    }
                    if (typeof element.dataset.srcheight !== 'undefined') {
                        element.style.height = `${ element.dataset.srcheight }px`;
                    }
                }
            });
        };
        lazy.init();
    });

    // Thumbnail lazy loading, e.g. on https://de.statista.com/statistik/aktuell/
    const thumbnails = document.querySelectorAll('img.tn');
    if (thumbnails.length) {
        thumbnails.forEach(element => {
            if ((typeof element.naturalWidth !== 'undefined' &&
                element.naturalWidth === 0) ||
                element.readyState === 'uninitialized'
            ) {
                element.setAttribute('src', `/thumbnail/${ element.dataset.id }`);
                console.error('broken:', element.dataset.id);
            }
        });
    }
}

export default miscInit();
