// Converts a string into a SEO friendly string, e. g. for URLs

function toSeoString(string) {
    const seoString = string
        .toLowerCase() // Convert to lower case
        .replaceAll(/\s+/g, '-') // Change whitespace to dashes
        .replaceAll(/ä/g, 'ae') // Replace umlauts
        .replaceAll(/ö/g, 'oe')
        .replaceAll(/ü/g, 'ue')
        .replaceAll(/ß/g, 'ss')
        .replaceAll(/æ/g, 'ae')
        .replaceAll(/ø/g, 'oe')
        .replaceAll(/å/g, 'aa')
        .replaceAll(/&/g, '-') // Replace ampersand
        .normalize('NFD') // Change diacritics
        .replaceAll(/[\u0300-\u036f]/g, '') // Remove illegal characters
        // eslint-disable-next-line no-useless-escape
        .replaceAll(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
        .replaceAll(/-+/g, '-') // Remove duplicate dashes
        .replace(/^-*/, '') // Remove starting dashes
        .replace(/-*$/, ''); // Remove trailing dashes

    return seoString;
}

export { toSeoString };
