//     PromoClick
//
//     Since we need the information on the previously clicked button,
//     we need to store that information in the <body> as data-attributes
//         -> data-promoviewid
//         -> data-promoviewname
//         -> data-promoviewcreative
//         -> data-promoviewposition
//
//     Then we'll check for click events on all popup buttons, prevent default
//     and push this promoClick.
//

const promoclicks = document.querySelectorAll('[data-promoclick]');
for (let i = 0, len = promoclicks.length; i < len; i++) {
    const element = promoclicks[i];
    element.addEventListener('click', event => {
        if (typeof google_tag_manager === 'object') {
            event.preventDefault();
            const url = event.currentTarget.getAttribute('href');

            const promoview = document.body.dataset.promoview;
            window.dataLayer.push({
                'event': 'promoClick',
                'eventTimeout': 1500,
                'eventCallback': () => {
                    document.location = url;
                },
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': promoview.id,
                                'name': promoview.name,
                                'creative': promoview.creative,
                                'position': promoview.pos,
                            }],
                    },
                },
            });
        }
    });
}

// Promoclick example:
// <a href="#" data-promoclickevent='{"name":"bla","creative":"camaign","position":"statistic_header","link":"https://de.statista.com/impressum/"}'></a>

function promoClick(_options) {
    const options = {
        id: typeof _options.id === 'undefined' ? 0 : _options.id,
        name: typeof _options.name === 'undefined' ? '' : _options.name,
        creative: typeof _options.creative === 'undefined' ? '' : _options.creative,
        position: typeof _options.position === 'undefined' ? '' : _options.position,
        link: typeof _options.link === 'undefined' ? false : _options.link,
    };

    if (options.id) {
        window.dataLayer.push({
            'event': 'promoClick',
            'eventTimeout': 1500,
            'eventCallback': () => {
                if (options.link) {
                    document.location = options.link;
                }
            },
            'ecommerce': {
                'promoClick': {
                    'promotions': [{
                        'id': options.id,
                        'name': options.name,
                        'creative': options.creative,
                        'position': options.position,
                    }],
                },
            },
        });
    }
}

const promoclickEvents = document.querySelectorAll('[data-promoclickevent]');
for (let i = 0, len = promoclickEvents.length; i < len; i++) {
    const element = promoclickEvents[i];
    element.addEventListener('click', event => {
        if (typeof google_tag_manager === 'object') {
            const data = JSON.parse(event.currentTarget.dataset.promoclickevent);
            if ('link' in data) {
                event.preventDefault();
            }
            promoClick(data);
        }
    });
}
