<!--
--  In order to render an overlay behind any native non-Vue element, this component needs to use a custom render function.
--  Use the "mount-to" property with a CSS selector to point where the overlay should be rendered.
--  Otherwise the overlay is rendered as a normal Vue component.
-->

<script>
    export default {
        props: {
            overlayShown: { type: Boolean, default: false },
            mountTo: { type: String, default: undefined },
            canAddHiddenClass: { type: Boolean, default: true },
        },
        data() {
            return {
                id: Date.now().toString(),
                containerClasses: this.customContainerClasses,
                overlayClasses: [this.canAddHiddenClass ? 'hidden' : ''],
                classTimeout: false,
                visible: this.overlayShown,
                element: null,
            };
        },
        watch: {
            overlayShown(fadeIn) {
                this.toggleOverlayClasses(fadeIn);
            },
        },
        methods: {
            removeStringFromArray(array, string) {
                const newArray = array.slice();
                newArray.splice(newArray.indexOf(string), 1);

                return newArray;
            },
            addStringToArray(array, string) {
                const newArray = array.slice();
                if (newArray.indexOf(string) === -1) {
                    newArray.push(string);
                }

                return newArray;
            },
            toggleOverlayClasses(fadeIn) {
                clearTimeout(this.classTimeout);
                if (fadeIn) {
                    this.visible = fadeIn;
                    this.overlayClasses = this.removeStringFromArray(this.overlayClasses, 'hidden');
                } else {
                    this.overlayClasses = this.addStringToArray(this.overlayClasses, 'vueModal__overlay--fadeOut');
                    this.classTimeout = setTimeout(() => {
                        this.visible = fadeIn;
                        this.overlayClasses = this.removeStringFromArray(this.overlayClasses, 'vueModal__overlay--fadeOut');
                        this.overlayClasses = this.addStringToArray(this.overlayClasses, 'hidden');
                    }, 300);
                }
            },
            renderNativeOverlay() {
                const mountPoint = document.querySelector(this.mountTo);
                const existingElement = document.getElementById(this.id);
                if (existingElement) {
                    existingElement.remove();
                }
                const nativeElement = document.createElement('div');
                nativeElement.setAttribute('id', this.id);
                nativeElement.style.display = this.visible ? 'block' : 'none';
                nativeElement.className = ['vueModal__overlay', this.overlayClasses].join(' ');
                nativeElement.addEventListener('click', () => {
                    this.$emit('toggle-modal', 'close');
                });
                mountPoint.insertBefore(nativeElement, mountPoint.firstChild);
                this.element = nativeElement;
            },
        },
        mounted() {
            window.addEventListener('keyup', event => {
                if (this.visible && (event.key === 'Escape' || event.key === 'Esc')) {
                    this.$emit('toggle-modal', 'close');
                }
            });
        },
        render(createElement) {
            if (this.mountTo) {
                this.renderNativeOverlay();

                return createElement('div');
            }

            const vueOverlay = createElement('div', {
                style: {
                    display: this.visible ? 'block' : 'none',
                },
                class: ['vueModal__overlay', this.overlayClasses],
                ref: 'overlay',
                on: {
                    click: () => {
                        this.$emit('toggle-modal', 'close');
                    },
                },
            });
            this.element = vueOverlay;

            return vueOverlay;
        },
    };
</script>
