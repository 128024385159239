/* Example:
 * <button data-push-event='{"category":"click all the things!", "action": "click on this!", "label": "duuuuude!"}'></button>
 */

function pushEvent(parameters) {
    window.dataLayer.push({
        event: 'eventTracking',
        eventCategory: parameters.category,
        eventAction: parameters.action,
        eventLabel: parameters.label,
    });
}

function initEventTracking() {
    document.addEventListener('click', event => {
        if (event.target.closest('[data-push-event]')) {
            const element = event.target.closest('[data-push-event]');
            const eventData = JSON.parse(element.getAttribute('data-push-event'));
            pushEvent(eventData);
        }
    });
}

export { initEventTracking, pushEvent };
