interface Variables {
    event: string,
    [index: string]: string | boolean,
}

/* eslint-disable  @typescript-eslint/no-unused-vars */
interface FilterForm {
    q: {
        value: string
    }
}


interface DefinedData {
    [index: string]: string | boolean,
}

interface SearchData {
    search_index: string,
    search_term: string | boolean,
}

interface DataLayerEvent {
    event: string;
    [key: string]: string;
}

interface userTrackingData {
    userId: number;
    userProductId: number;
    userPlatformId: number;
    userCountryId: number;
    userFirstname: string;
    userLastname: string;
    userLanguage: string;
    userPhase: string;
    userTestGroup: string;
    userTypeAccount: string;
    userTypeProduct: string;
    userEmail: string;
    subscriptionRightApi: boolean;
    subscriptionRightCdb: boolean;
    subscriptionRightGcs: boolean;
    subscriptionRightPendo: boolean;
    subscriptionRightPpt: boolean;
    lastvisitContentId: string;
    lastvisitPageSlug: string;
    userDateRegistered: string;
    lastvisitLoggedin: boolean;
    lastvisitIp: string;
    lastvisitCountryIp: string;
    groupCompanyName: string;
    lastvisitContentTitle: string;
    lastvisitContentType: string;
    lastvisitContentAccess: string;
    groupId: string;
    groupCountryId: string;
}

interface TrackingWindow extends Window {
    tracking?: userTrackingData;
}

declare let window: TrackingWindow;

function getDataLayerInitProperty(propertyName: string) : string | boolean | undefined {
    const initEvent = window.dataLayer.find((event: DataLayerEvent) => event.event === 'init');
    if (initEvent && propertyName in initEvent) {
        const value = initEvent[propertyName];
        if (typeof value === 'boolean') {
            return value;
        } else {
            return String(value);
        }
    }

    return undefined;
}

const trackingData = () => {
    return {
        agent: {
            pendo: (() => {
                const pendo: HTMLElement = document.querySelector('[data-pendo-config]');
                if (pendo) {
                    return JSON.parse(pendo.dataset.pendoConfig);
                }
            })(),
        },
        platform: {
            abtest: (() => {
                const body = document.querySelector('body');

                return body.dataset.group;
            })(),
            metaTag: ((id: string) => {
                let result = null;
                const tag: HTMLElement = document.querySelector(`#${ id }`);
                if (tag) {
                    result = tag.dataset.page;
                }

                return result;
            }),
            metaDl: ((id: string, key: string) => {
                let result = null;
                const gtmContentview: HTMLElement = document.querySelector(`#${ id }`);
                if (gtmContentview) {
                    result = JSON.parse(gtmContentview.dataset.page)[key];
                }

                return result;
            }),
        },
        location: {
            params: (() => {
                let result = null;

                if (window.location.search) {
                    result = window.location.search;
                }

                return result;
            })(),
            platform: (() => {
                let result = null;
                if (window.location.host.indexOf('.statista.com')) {
                    result = window.location.host.replace('.statista.com', '');
                }

                return result;
            })(),
            slug: (() => {
                let result = null;
                if (window.location.pathname) {
                    result = window.location.pathname;
                    if (result.charAt(0) === '/') {
                        result = result.substr(1);
                    }
                    if (result.charAt(result.length - 1) === '/') {
                        result = result.substr(0, result.length - 1);
                    }
                    if (result === '') {
                        result = null;
                    }
                }

                return result;
            })(),
        },
    };
};

window.trackingData = trackingData;
const { pendo } = trackingData().agent;

/**
 * @param variables {object}
 */
function push(variables: Variables) {
    if (!variables || !variables.event) {
        return;
    }
    const definedData: DefinedData = {};
    for (const prop in variables) {
        if (
            Object.prototype.hasOwnProperty.call(variables, prop) &&
            variables[prop] !== undefined &&
            variables[prop] !== null &&
            variables[prop] !== ''
        ) {
            definedData[prop] = variables[prop];
        }
    }
    window.dataLayer.push(definedData);
}

export function init() {
    window.dataLayer = window.dataLayer || [];
    const eventObject = {
        event: 'init',
        // User-related variables
        user_account_type_id: trackingData()?.platform?.metaTag('gtm_accountTypeId'),
        user_id: String(window.tracking?.userId),
        user_log: window.tracking?.lastvisitLoggedin,
        user_phase: window.tracking?.userPhase,
        user_product_group: window.tracking?.userTypeProduct,
        user_product_id: String(window.tracking?.userProductId),
        user_ab_test_version: window.tracking?.userTestGroup,
        user_country_id: String(window.tracking?.userCountryId),
        user_date_registered: window.tracking?.userDateRegistered,
        user_email: window.tracking?.userEmail,
        user_firstname: window.tracking?.userFirstname,
        user_lastname: window.tracking?.userLastname,
        user_language: window.tracking?.userLanguage,
        user_lastvisit_ip: window.tracking?.lastvisitIp,
        user_platform_id: String(window.tracking?.userPlatformId),
        user_lastvisit_locale: window.tracking?.lastvisitCountryIp,
        group_cancelleddate: pendo?.visitor?.tstampCancelled,
        group_enddate: pendo?.account?.tstampSubscriptionStop,
        group_startdate: pendo?.account?.tstampSubscriptionStart,
        group_level: pendo?.visitor?.planLevel,
        group_id: window.tracking?.groupId,
        group_country_id: window.tracking?.groupCountryId,
        group_lastvisit_locale: pendo?.visitor?.locale,
        group_right_api: window.tracking?.subscriptionRightApi,
        page_slug: window.tracking?.lastvisitPageSlug,
        group_right_cdb: window.tracking?.subscriptionRightCdb,
        group_right_gcs: window.tracking?.subscriptionRightGcs,
        group_right_ppt: window.tracking?.subscriptionRightPpt,
        content_id: window.tracking?.lastvisitContentId,
        group_companyname: window.tracking?.groupCompanyName,
        page_title: window.tracking?.lastvisitContentTitle,
        page_type: window.tracking?.lastvisitContentType,
        content_access_type: window.tracking?.lastvisitContentAccess,
        content_editor_id: trackingData()?.platform?.metaDl('dl_gtm_contentview', 'contentEditor'),
        content_editor_team_id: trackingData()?.platform?.metaTag('gtm_stat_editorTeam'),
        content_has_extended_description: trackingData()?.platform?.metaTag('gtm_stat_ers'),
        content_pin_status: trackingData()?.platform?.metaTag('gtm_stat_pinStatus'),
        content_paywall_view: trackingData()?.platform?.metaTag('gtm_stat_userView'),
        content_date_edit: trackingData()?.platform?.metaTag('gtm_stat_dateEdit'),
        content_date_released: trackingData()?.platform?.metaTag('gtm_stat_dateReleased'),
        content_l2_list: trackingData()?.platform?.metaTag('gtm_stat_l2List'),
        content_country: trackingData()?.platform?.metaTag('gtm_stat_country'),
        content_update_frequency: trackingData()?.platform?.metaTag('gtm_stat_frequency'),
        content_main_branch: trackingData()?.platform?.metaDl('dl_gtm_contentview', 'contentMainSector'),
        content_master_branch: trackingData()?.platform?.metaTag('gtm_stat_masterBranch') ?? trackingData()?.platform?.metaTag('gtm_infographic_masterBranch'),
        content_branch_1: trackingData()?.platform?.metaTag('gtm_stat_branch1'),
        content_branch_2: trackingData()?.platform?.metaTag('gtm_stat_branch2'),
        content_continent: trackingData()?.platform?.metaTag('gtm_stat_continent'),
        content_relevance: trackingData()?.platform?.metaTag('gtm_stat_relevance'),
        content_description_length: trackingData()?.platform?.metaTag('gtm_stat_rsLength'),
        content_sources_id: trackingData()?.platform?.metaTag('gtm_content_sourcesID'),
        content_conducted_by_id: trackingData()?.platform?.metaTag('gtm_content_conductedByID'),
        content_published_by_id: trackingData()?.platform?.metaTag('gtm_content_publishedByID'),
        automated_test: trackingData()?.platform?.metaTag('gtm_automatedTest'),
        cookie_consent_enabled: trackingData()?.platform?.metaTag('gtm_cookieConsentEnabled'),
        study_method_desc: trackingData()?.platform?.metaTag('gtm_study_methodDesc'),
        study_method_id: trackingData()?.platform?.metaTag('gtm_study_methodId'),
        study_study_type_id: trackingData()?.platform?.metaTag('gtm_study_studyTypeId'),
        user_last_content_id: trackingData()?.platform?.metaTag('gtm_lastContentId'),
        content_id_topic: trackingData()?.platform?.metaTag('gtm_infographic_idTopic'),
        content_tag1: trackingData()?.platform?.metaTag('gtm_infographic_tag1'),
        content_topic: trackingData()?.platform?.metaTag('gtm_infographic_topic'),
        content_graph_sort: trackingData()?.platform?.metaTag('gtm_stat_graphSort'),
        content_graph_type: trackingData()?.platform?.metaTag('gtm_stat_graphType'),
        content_survey_period: trackingData()?.platform?.metaTag('gtm_stat_surveyPeriod'),
        content_update_type: trackingData()?.platform?.metaTag('gtm_stat_updateType'),
        content_actualization_frequency: trackingData()?.platform?.metaTag('gtm_stat_updateType'),
        content_master_tag_1: trackingData()?.platform?.metaTag('gtm_stat_masterTag1') ?? trackingData()?.platform?.metaTag('gtm_infographic_masterTag1'),
        content_master_tag_2: trackingData()?.platform?.metaTag('gtm_stat_masterTag2') ?? trackingData()?.platform?.metaTag('gtm_infographic_masterTag2'),
        content_master_tag_3: trackingData()?.platform?.metaTag('gtm_stat_masterTag3'),
        usage_view_origin_page: trackingData()?.platform?.metaTag('usageView_origin_page'),
        usage_view_origin_referer: trackingData()?.platform?.metaTag('usageView_origin_referer'),
        usage_view_origin_channel: trackingData()?.platform?.metaTag('usageView_origin_channel'),
        stroeer_page_type: trackingData()?.platform?.metaTag('gtm_stroeer_pageType'),
        stroeer_g_id: trackingData()?.platform?.metaTag('gtm_stroeer_gid'),
    };
    push(eventObject);
}



function pushCheckoutAccount(accountType: string) {
    const account_type = accountType.toLowerCase().split('-').join('_').split(' ').join('_');
    const isUpgrade = window.location.pathname.includes('23');
    const registration_type = isUpgrade ? 'upgrade' : 'direct';
    const funnelFirstStep = window.location.pathname.includes('create');
    const funnelSecondStep = window.location.pathname.includes('address');
    const funnelThirdStep = window.location.pathname.includes('checkout');
    const paymentMethodInvoice = window.location.pathname.includes('invoice');
    const paymentMethodCreditCard = window.location.pathname.includes('credit-card');
    const payment_type = paymentMethodInvoice ? 'invoice' : paymentMethodCreditCard ? 'credit-card' : null;
    const funnelStepProgress = funnelFirstStep ? '1_create' : funnelSecondStep ? '2_address' : funnelThirdStep ? '3_checkout' : '4_success';
    const eventObject = {
        event: 'checkout_account',
        account_type,
        funnel_step: funnelStepProgress,
        purchase_journey: registration_type,
        product_id: getDataLayerInitProperty('user_product_id'),
        payment_method: payment_type,
    };
    push(eventObject);
}

function pushCheckoutAccountCriteo(productId: string, productPrice: string) {
    const eventObject = {
        event: 'account_checkout_criteo',
        account_type: productId,
        price: productPrice,
        product_id: getDataLayerInitProperty('user_product_id'),
    };
    push(eventObject);
}

function pushCheckoutAccountBasicForm() {
    const eventObject = {
        event: 'checkout_account',
        account_type: 'basic_account',
        product_id: getDataLayerInitProperty('user_product_id'),
    };
    push(eventObject);
}

function pushLogin() {
    const eventObject = {
        event: 'login',
        user_id: getDataLayerInitProperty('user_id'),
    };
    push(eventObject);
}

function pushLogout() {
    const eventObject = {
        event: 'logout',
        user_id: 'null',
    };
    push(eventObject);
}

function pushRequestAccount(account_type: string, request_group: string) {
    const eventObject = {
        event: 'request_account',
        account_type,
        product_id: getDataLayerInitProperty('user_product_id'),
        request_group,
    };
    push(eventObject);
}

function pushBrowseAccount(accountType: string | string[]) {
    const pushEventObject = (accountType: string) => {
        const eventObject = {
            event: 'browse_account',
            account_type: accountType,
        };
        push(eventObject);
    };

    if (typeof accountType === 'string') {
        const account_type = accountType?.toLowerCase()?.split('-')?.join('_')?.split(' ')?.join('_');
        pushEventObject(account_type);
    } else {
        for (const account_type of accountType) {
            pushEventObject(account_type);
        }
    }
}

function pushRegisterAccount(accountType: string) {
    const isUpgrade = window.location.pathname.includes('23');
    const registration_type = isUpgrade ? 'upgrade' : 'new';
    const account_type = accountType?.toLowerCase()?.split('-')?.join('_')?.split(' ')?.join('_');
    const eventObject = {
        event: 'register_account',
        account_type,
        registration_type,
    };
    push(eventObject);
}

function pushSubscribeNewsletter(newsletter_type: string) {
    const eventObject = {
        event: 'subscribe_newsletter',
        newsletter_type,
    };
    push(eventObject);
}

function pushConfirmNewsletter(newsletter_type: string) {
    const eventObject = {
        event: 'confirm_newsletter',
        newsletter_type,
    };
    push(eventObject);
}

function pushInteraction(interaction_name: string, interaction_detail: string) {
    const eventObject = {
        event: 'interaction',
        interaction_name,
        interaction_detail,
    };
    push(eventObject);
}

function pushViewJobListing(vacancy: string) {
    const job = JSON.parse(vacancy);
    const eventObject = {
        event: 'view_job_listing',
        job_title: job?.title,
        job_id: job?.jobNumber,
        job_department: job.category?.trim(),
        job_location: job?.locations,
    };
    push(eventObject);
}

function pushJobApplication(vacancy: string) {
    const job = JSON.parse(vacancy);
    const eventObject = {
        event: 'send_job_application',
        job_title: job?.title,
        job_id: job?.jobNumber,
        job_department: job?.category?.trim(),
        job_location: job?.locations,
    };
    push(eventObject);
}

const SEARCH_INDEX = {
    GENERAL: 'search',
    STUDIES: 'studies',
    OUTLOOK: 'outlook',
    COMPANY_DB: 'company_db',
};

function pushSearch(searchData: SearchData) {
    push({
        event: 'search',
        search_index: SEARCH_INDEX.GENERAL,
        ...searchData,
    });
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function pushSearchClick({ form }: any) {
    const eventObject = {
        event: 'search_click',
        search_term: form?.q?.value,
        search_index: SEARCH_INDEX.GENERAL,
        /* search_position: -1, */
    };
    push(eventObject);
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function pushSearchClickCompanyDb({ searchTerm, searchPosition }: any) {
    const eventObject = {
        event: 'search_click',
        search_term: searchTerm,
        search_index: SEARCH_INDEX.COMPANY_DB,
        search_position: searchPosition,
    };
    push(eventObject);
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function pushStudiesSearch({ result, form }: any) {
    const searchData = {
        search_index: SEARCH_INDEX.STUDIES,
        search_term: form?.q?.value,
        search_filtercountry: form?.idCountry?.value,
        search_filterindustry: form?.idBranch?.value,
        search_resultsCount: result?.totalResults,
        'search_resultsPerType-report': result?.reportStudies?.length,
        search_sort: form?.sortMethod?.value,
    };

    pushSearch(searchData);
}

interface OutlookSearchData {
    searchTerm: string | boolean,
    results: {
        [index: string]: string
    }
}
function pushOutlookSearch({ results, searchTerm }: OutlookSearchData) {
    const searchData = {
        search_index: SEARCH_INDEX.OUTLOOK,
        search_term: searchTerm,
        search_resultsCount: results?.total_found,
    };

    pushSearch(searchData);
}

function pushCompanyDbSearch({ filterParameter }: any) {
    const companyDbSearchData = {
        search_index: SEARCH_INDEX.COMPANY_DB,
        search_term: filterParameter.search_term,
        search_database: filterParameter.search_database,
        search_results_total: filterParameter.search_results_total,
    };

    pushSearch(companyDbSearchData);
}

enum ContentType {
    STATISTICS = 'statistics',
    STUDIES = 'studies',
    INFOGRAPHICS = 'infographics',
    FORECAST = 'forecast',
    TOPICS = 'topics',
    COMPANY_INSIGHTS = 'company_insights',
    MARKET_INSIGHTS = 'market_insights',
    CONSUMER_INSIGHTS = 'consumer_insights',
}

function pushFileDownload(contentType: ContentType, fileName: string, fileExtension: string, data_gtm: string) {
    const eventObject = {
        event: 'file_download',
        content_type: contentType.valueOf(),
        file_name: fileName,
        file_extension: fileExtension,
        data_gtm: data_gtm,
    };
    push(eventObject);
}

function pushWebinarParticipation(webinar_url: string, webinar_title: string, data_gtm: string) {
    const eventObject = {
        event: 'webinar_participation',
        webinar_url: webinar_url,
        webinar_title: webinar_title,
        data_gtm: data_gtm,
    };
    push(eventObject);
}

function pushContactFormSent() {
    const eventObject = {
        event: 'contact_form_sent',
    };
    push(eventObject);
}

function pushRequestFullAccess(contentType: ContentType, pageLocation: string) {
    const eventObject = {
        event: 'request_full_access',
        content_type: contentType,
        page_location: pageLocation,
    };
    push(eventObject);
}

function pushOrderReport(contentType: ContentType, pageLocation: string) {
    const eventObject = {
        event: 'order_report',
        content_type: contentType,
        page_location: pageLocation,
    };
    push(eventObject);
}

function pushGetAccess(contentType: ContentType, pageLocation: string) {
    const eventObject = {
        event: 'get_access',
        content_type: contentType,
        page_location: pageLocation,
    };
    push(eventObject);
}


function pushShowData(contentType: ContentType, pageLocation: string) {
    const eventObject = {
        event: 'show_data',
        content_type: contentType,
        page_location: pageLocation,
    };
    push(eventObject);
}

function pushSubscribeForUpdates(contentType: ContentType, contentId: string) {
    const eventObject = {
        event: 'subscribe_for_updates',
        content_type: contentType.valueOf(),
        content_id: contentId,
    };
    push(eventObject);
}


export const dataLayer = {
    customEvent: {
        trackingData,
        push,
    },
    ContentType,
    pushJobApplication,
    pushViewJobListing,
    pushInteraction,
    pushConfirmNewsletter,
    pushSubscribeNewsletter,
    pushRegisterAccount,
    pushBrowseAccount,
    pushRequestAccount,
    pushCheckoutAccount,
    pushCheckoutAccountBasicForm,
    pushCheckoutAccountCriteo,
    pushStudiesSearch,
    pushOutlookSearch,
    pushCompanyDbSearch,
    pushSearchClick,
    pushSearchClickCompanyDb,
    pushLogin,
    pushLogout,
    pushFileDownload,
    pushWebinarParticipation,
    pushContactFormSent,
    pushRequestFullAccess,
    pushOrderReport,
    pushGetAccess,
    pushShowData,
    pushSubscribeForUpdates,
};
