import { getArray } from 'tools/domHelpers';
import { lazyInView as isInViewFilter } from './lazyInView';

const UNVEIL_EVENT_NAME = 'unveil';
const UNVEIL_EVENT = new CustomEvent(UNVEIL_EVENT_NAME);

class LazyLoader {
    constructor(selector, autoinit = false, setOpacity = true) {
        this.selector = selector;
        this.parent = null;
        this.threshold = 0;
        this.setOpacity = setOpacity;
        this.retina = window.devicePixelRatio > 1;
        if (autoinit) {
            this.init();
        }
    }

    init() {
        this.lazyImages = this.parent ?
            getArray(this.parent.querySelectorAll(this.selector)) :
            getArray(document.querySelectorAll(this.selector));
        this.lazyImages.forEach(lazyImage => {
            lazyImage.addEventListener(UNVEIL_EVENT_NAME, () => this.showImage(lazyImage, this.retina, this.callback), { once: true });
        });

        window.addEventListener(UNVEIL_EVENT_NAME, () => {
            this.unveil(this.lazyImages, this.threshold);
        });
        window.addEventListener('scroll', () => {
            this.unveil(this.lazyImages, this.threshold);
        });
        window.addEventListener('resize', () => {
            this.unveil(this.lazyImages, this.threshold);
        });

        this.lazyImages = this.unveil(this.lazyImages, this.threshold);
    }

    unveil(lazyImages, threshold) {
        const inviewImages = lazyImages.filter(element => isInViewFilter(element, threshold));
        inviewImages.forEach(image => image.dispatchEvent(UNVEIL_EVENT));

        return lazyImages.filter(element => !isInViewFilter(element, threshold));
    }

    replaceAndSetOpacity(img, chosenSrc, callback) {
        img.setAttribute('src', chosenSrc);
        if (typeof callback === 'function') {
            callback(img);
        }
        if (this.setOpacity) {
            img.style.opacity = '1';
        }
    }

    showImage(lazyImage, isRetina, callback) {
        let chosenSrc = '';
        const srcAttributes = isRetina ? ['data-src-retina'] : [];
        srcAttributes.push('data-src', 'srcset');
        for (const srcAttribute of srcAttributes) {
            chosenSrc = lazyImage.getAttribute(srcAttribute);
            if (!chosenSrc) {
                continue;
            }
            if (srcAttribute === 'srcset') {
                chosenSrc = lazyImage.currentSrc;
            }
            if (lazyImage.complete) {
                this.replaceAndSetOpacity(lazyImage, chosenSrc, callback);
            } else {
                lazyImage.addEventListener('load', this.replaceAndSetOpacity(lazyImage, chosenSrc, callback));
            }
            break;
        }
    }
}

export { LazyLoader, UNVEIL_EVENT };
