import { getCookie, createCookie } from 'tools/cookies';

const COOKIE_ACCEPTANCE_NAME = 'OptanonConsent';
const PROP_GROUPS = 'groups';
const PROP_HOSTS = 'hosts';

export class OneTrustCookieRepository {
    /**
     * @param {function} _getCookie
     * @param {function} _createCookie
     */
    constructor(
        _getCookie = getCookie,
        _createCookie = createCookie,
    ) {
        this.getCookie = _getCookie;
        this.createCookie = _createCookie;
        this.acceptance = this.getUserAcceptedGroupsAndHosts();
    }

    /**
     * @param {array} cookieGroups
     *
     * @returns {boolean}
     */
    acceptedByGroups(cookieGroups) {
        return this.isAcceptanceMatched(PROP_GROUPS, cookieGroups);
    }

    /**
     * @param {array} cookieHosts
     *
     * @returns {boolean}
     */
    acceptedByHosts(cookieHosts) {
        return this.isAcceptanceMatched(PROP_HOSTS, cookieHosts);
    }

    /**
     *
     * @param {string} propertyName
     * @param {array} cookieGroupsOrHosts
     *
     * @returns {boolean}
     */
    isAcceptanceMatched(propertyName, cookieGroupsOrHosts) {
        if (this.acceptance === null || !Object.prototype.hasOwnProperty.call(this.acceptance, propertyName)) {
            return false;
        }
        const acceptanceArray = this.acceptance[propertyName];

        return cookieGroupsOrHosts.filter(function(n) {
            return acceptanceArray.indexOf(n) !== -1;
        }).length > 0;
    }

    /**
     * @returns {object}
     */
    getUserAcceptedGroupsAndHosts() {
        const acceptanceCookie = this.getCookie(COOKIE_ACCEPTANCE_NAME);

        if (acceptanceCookie === null) {
            return null;
        }

        return this.parseOneTrustCookie(acceptanceCookie);
    }

    /**
     * @param cookieString {string}
     *
     * @returns {*}
     */
    parseOneTrustCookie(cookieString) {
        const parsedFirstLevel = cookieString.split('&').reduce((cookiePropObject, cookieProp) => {
            const key = cookieProp.split('=')[0];
            cookiePropObject[key] = decodeURIComponent(cookieProp.split('=')[1]);

            return cookiePropObject;
        }, {});

        const accepted = {};
        accepted[PROP_GROUPS] = this.parseValues(parsedFirstLevel[PROP_GROUPS]);
        accepted[PROP_HOSTS] = this.parseValues(parsedFirstLevel[PROP_HOSTS]);

        return accepted;
    }

    /**
     * @param {string} valueString
     *
     * @returns {*}
     */
    parseValues(valueString) {
        return valueString.split(',').reduce((accumulator, item) => {
            const group = item.split(':')[0];
            if (item.split(':')[1] === '1') {
                accumulator.push(group);
            }

            return accumulator;
        }, []);
    }
}
