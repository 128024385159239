import { DomReady } from 'tools/domready';
import { UNVEIL_EVENT } from './lazyLoader/lazyLoader';
import { replaceState } from 'tools/replaceState';

const ENTER_KEY_CODE = 13;

function tabInit() {
    DomReady(() => {
        /* old tab toggler => use data-tabbar instead */
        const tabGroups = document.querySelectorAll('.tabGroup');
        if (tabGroups.length) {
            tabGroups.forEach(tabGroup => {
                const tabsContainer = tabGroup.querySelector('.tabs');
                if (tabsContainer) {
                    tabsContainer.setAttribute('role', 'tablist');
                }
                tabGroup.querySelectorAll('.tabContent').forEach(tabContent => {
                    tabContent.style.removeProperty('display');
                });

                const tabs = tabGroup.querySelectorAll('.tabs__tab');
                const activeTab = tabGroup.querySelector('.tabs__tab--active');
                tabs.forEach((tab, index) => {
                    if ((activeTab && activeTab === tab) || (!activeTab && index === 0)) {
                        setActiveTab(tabs, tab);
                        showTabContent(tabGroup, tab);
                    }
                    tab.addEventListener('keyup', event => {
                        if (event.keyCode === ENTER_KEY_CODE) {
                            tab.click();
                        }
                    });
                    tab.addEventListener('click', event => {
                        const currentTab = event.currentTarget;
                        setActiveTab(tabs, currentTab);
                        showTabContent(tabGroup, currentTab);
                    });
                });
            });
            initHash();
        }

        function clickTab(currentTab, tabs, tabGroup) {
            setActiveTab(tabs, currentTab);
            showTabContent(tabGroup, currentTab);
        }

        function initHash() {
            // Open Tab if hash is set
            const tabs = document.querySelector('.tabs');
            if (window.location.hash && tabs) {
                const hash = window.location.hash.substring(1);
                if (hash && hash !== '0') {
                    let currentTab = tabs.querySelector(`#${ hash }`);
                    if (!currentTab) {
                        currentTab = tabs.querySelector(`[data-hash="${ hash }"]`);
                    }
                    if (currentTab) {
                        const tabGroup = currentTab.closest('.tabGroup');
                        const tabGrouptabs = tabGroup.querySelectorAll('.tabs__tab');
                        setActiveTab(tabGrouptabs, currentTab);
                        showTabContent(tabGroup, currentTab);
                    }
                }
            }
        }

        function setActiveTab(tabs, currentTab) {
            tabs.forEach(tab => {
                tab.classList.remove('tabs__tab--active');
                tab.setAttribute('aria-selected', 'false');
            });

            // add active and selected to current tab
            currentTab.classList.add('tabs__tab--active');
            currentTab.setAttribute('aria-selected', 'true');
        }

        function showTabContent(tabGroup, currentTab) {
            // find the right content and do the same
            tabGroup.querySelectorAll('.tabContent').forEach(tabContent => {
                tabContent.setAttribute('hidden', true);
                tabContent.classList.add('hidden');
            });

            const targetContent = currentTab.getAttribute('rel');
            if (targetContent) {
                const currentTabContent = document.getElementById(targetContent);
                if (currentTabContent) {
                    currentTabContent.removeAttribute('hidden');
                    currentTabContent.classList.remove('hidden');
                }
                const lazyImages = currentTabContent.querySelectorAll('img.lazy');
                if (lazyImages.length) {
                    lazyImages.forEach(lazyImage => {
                        lazyImage.dispatchEvent(UNVEIL_EVENT);
                        lazyImage.classList.remove('lazy');
                    });
                }
            }
        }

        /*  Tab toggler
            Use elements with following data-tags:
                data-tabbar         ="{arbitraryElementName1}"     tabbar wrap; element name will be user to prefix --active class
                data-tabbar-target  ="{arbitraryElementName1}"     for each tab
                data-tabbar-content ="{arbitraryElementName1}"     for content containers
            */
        (() => {
            const tabbars = document.querySelectorAll('[data-tabbar]');

            tabbars.forEach(tabbar => {
                const blockName = tabbar.dataset.tabbar;
                const activeClass = blockName ? `${ blockName }--active` : 'active';

                const findCorrectParent = (parent, parentSelector, childSelector) => {
                    const elements = [];
                    parent.querySelectorAll(childSelector).forEach(child => {
                        const correctParent = child.closest(parentSelector) === parent;
                        if (correctParent) {
                            elements.push(child);
                        }
                    });

                    return elements;
                };

                const clickTab = (clickEvent) => {
                    const currentTab = clickEvent.target.closest('[data-tabbar-target]');
                    const activeContentElement = currentTab.dataset.tabbarTarget;
                    const changeUrl = currentTab.dataset.tabbarUrl;

                    if (!currentTab.classList.contains('tabbar__tab--disabled') && !currentTab.classList.contains('tabbar__tab--disableClick')) {
                        const tabbarContents = findCorrectParent(tabbar, '[data-tabbar]', '[data-tabbar-content]');
                        const currentTabbarContent = tabbar.querySelector(`[data-tabbar-content=${ activeContentElement }]`);
                        tabbarContents.forEach(tabbarContent => {
                            tabbarContent.classList.add('hidden');
                            tabbarContent.classList.remove(activeClass);
                        });
                        currentTabbarContent.classList.remove('hidden');
                        currentTabbarContent.classList.add(activeClass);
                        const lazyImages = currentTabbarContent.querySelectorAll('img.lazy');
                        if (lazyImages.length) {
                            lazyImages.forEach(lazyImage => {
                                lazyImage.dispatchEvent(UNVEIL_EVENT);
                                lazyImage.classList.remove('lazy');
                            });
                        }
                        tabs.forEach(tab => {
                            tab.classList.remove(activeClass);
                        });
                        currentTab.classList.add(activeClass);

                        // a11y features if available
                        if (currentTab.hasAttribute('aria-selected')) {
                            tabs.forEach(tab => {
                                tab.setAttribute('aria-selected', 'false');
                            });
                            currentTab.setAttribute('aria-selected', 'true');

                            tabbarContents.forEach(tabbarContent => {
                                tabbarContent.setAttribute('hidden', true);
                            });
                            if (currentTabbarContent) {
                                currentTabbarContent.removeAttribute('hidden');
                            }
                        }
                    }
                    if (changeUrl !== undefined) {
                        replaceState(changeUrl);
                    }
                };

                const tabs = findCorrectParent(tabbar, '[data-tabbar]', '[data-tabbar-target]');
                tabs.forEach(tab => {
                    tab.addEventListener('click', clickTab);

                    tab.addEventListener('keypress', event => {
                        if (event.keyCode === ENTER_KEY_CODE) {
                            clickTab(event);
                        }
                    });
                });
            });
        })();
    });
}

export default tabInit();
