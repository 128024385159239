export const EVENT_DEFAULT = {
    INTERVAL: 0,
    ACCURACY: 'and',
    REGION: 0,
    LOCATION_FOCUS: -1,
    CATEGORY: 0,
    SORT_METHOD: 'idrelevance',
    ARCHIVE: 0,
    PAGE: 1,
};
