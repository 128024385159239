var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c(
          "div",
          {
            staticClass: "autocompleteList autocompleteList--old",
            style: [_vm.styles, _vm.widthStyle],
          },
          [
            _c(
              "ul",
              { staticClass: "autocompleteList__results" },
              _vm._l(_vm.axiosResult, function (item, index) {
                return _c(
                  "li",
                  {
                    key: item,
                    staticClass: "autocompleteList__resultsItem",
                    class: {
                      "autocompleteList__item--selected":
                        index === _vm.selectedResult,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm(item)
                      },
                    },
                  },
                  [
                    _vm.submitSelfAction
                      ? _c("a", {
                          staticClass:
                            "autocompleteList__item autocompleteList__item--linkReset",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.highlightSearch(item, _vm.search)
                            ),
                          },
                        })
                      : _c("a", {
                          staticClass:
                            "autocompleteList__item autocompleteList__item--linkReset",
                          attrs: {
                            href: `/statistik/suche/?q=${_vm.encodeItem(item)}`,
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.highlightSearch(item, _vm.search)
                            ),
                          },
                        }),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }