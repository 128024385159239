// Equvalent to php str_replace() function with array support.

export default function(string, findArray, replaceArray) {
    let i, regex = [];
    const map = {};

    for (i = 0; i < findArray.length; i++){
        regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, '\\$1'));
        map[findArray[i]] = replaceArray[i];
    }

    regex = regex.join('|');
    string = string.replace(new RegExp(regex, 'g'), function(matched) {
        return map[matched];
    });

    return string;
}
