function DomReady(onDomReadyCallback) {
    initConfigObject();
    bindReady();

    // If the DOM is already ready
    if (window.domReady.isReady) {
        // Execute the function immediately
        onDomReadyCallback.call(window, []);
    } else {
        // Add the function to the wait list
        window.domReady.domReadyList.push(function() { return onDomReadyCallback.call(window, []); });
    }
}

function initConfigObject() {
    if (typeof window.domReady === 'undefined') {
        window.domReady = {
            readyBound: false,
            isReady: false,
            domReadyList: [],
        };
    }
}

function bindReady() {
    if (window.domReady.readyBound) {
        return;
    }

    window.domReady.readyBound = true;
    document.addEventListener('DOMContentLoaded', domReady, false);
}

// Handle when the DOM is ready
function domReady() {
    // Make sure that the DOM is not already loaded
    if (!window.domReady.isReady) {
        // Remember that the DOM is ready
        window.domReady.isReady = true;

        if (window.domReady.domReadyList) {
            for (let fn = 0; fn < window.domReady.domReadyList.length; fn++) {
                window.domReady.domReadyList[fn].call(window, []);
            }

            window.domReady.domReadyList = [];
        }
    }
}

export { DomReady };
