var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifications" },
    _vm._l(_vm.notifications, function (notificationGroup, groupKey) {
      return _c(
        "div",
        { key: groupKey, class: `notifications__${groupKey}` },
        _vm._l(notificationGroup, function (notification) {
          return _c(
            "notification",
            {
              key: notification.id,
              class: `notification--${notification.position}`,
              attrs: {
                duration: notification.duration,
                icon: notification.icon,
                classes: notification.classes,
              },
              on: {
                hide: function ($event) {
                  return _vm.removeNotification(notification, groupKey)
                },
              },
            },
            [
              notification.title
                ? _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(notification.title)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { attrs: { slot: "text" }, slot: "text" }, [
                _vm._v(_vm._s(notification.text)),
              ]),
            ]
          )
        }),
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }