import searchStore from 'search/searchStore';

const MAX_CHARS = 500;

export function encodeItemContentType(contentType){
    let encodedItemContentType = '';

    switch (contentType){
        case 'Statistic':
        case 'Forecast':
            encodedItemContentType = 'SA';
            break;
        case 'Topic':
            encodedItemContentType = 'TO';
            break;
        case 'Report':
            encodedItemContentType = 'SU';
            break;
        case 'Infographic':
            encodedItemContentType = 'IG';
            break;
        case 'Company Insight':
            encodedItemContentType = 'CI';
            break;
        case 'Consumer Insight':
            encodedItemContentType = 'GC';
            break;
        case 'eCommerce Insights':
            encodedItemContentType = 'EI';
            break;
        case 'Market Insight':
            encodedItemContentType = 'MI';
            break;
    }

    return encodedItemContentType;
}

// Encode results from page into Content type + ID and returns an array of strings
export function encodeItemResultToDatalayer(resultArray) {
    const getGroupType = searchStore.getters['search/getGroupType'];
    const groupedPageResults = [];
    let currentPageResults = [];
    let charCounter = 0;

    resultArray.forEach(item => {
        const encodedContentType = encodeItemContentType(getGroupType(item));
        const encodedItem = encodedContentType+item.idcontent;

        if (charCounter + encodedItem.length > MAX_CHARS){
            groupedPageResults.push(currentPageResults);
            currentPageResults = [];
            charCounter = 0;
        }

        currentPageResults.push(encodedItem);
        charCounter += encodedItem.length;
    });

    if (currentPageResults.length > 0){
        groupedPageResults.push(currentPageResults);
    }

    return groupedPageResults;

}
