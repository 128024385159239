function replaceState(url, noHash = false) {
    if (history.replaceState) {
        // make # in front of the url if it's not empty
        if (url.length) {
            history.replaceState(null, null, noHash ? url : `#${ url }`);
        } else {
            // clear hash
            history.replaceState(null, null, ' ');
        }
    } else {
        location.hash = url;
    }
}

export { replaceState };
