<template>
    <div
        class="notification"
        :class="[{ 'notification--fadeOut': isFadingOut }, classes]">
        <div class="notification__wrapper">
            <div
                v-if="iconClass"
                class="notification__icon"
                :class="iconClass.class">
                <svg
                    class="stat__svg--notification"
                    :viewBox="iconClass.viewBox"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    aria-hidden="true">
                    <path :d="iconClass.path" />
                </svg>
            </div>
            <div class="notification__content">
                <h3
                    v-if="$slots.title"
                    class="notification__title">
                    <slot name="title"></slot>
                </h3>
                <p class="notification__text">
                    <slot name="text"></slot>
                </p>
            </div>
        </div>
        <span
            class="notification__close"
            @click="hideNotification"></span>
    </div>
</template>

<script>
    export default {
        props: {
            duration: {
                type: Number,
                default: 5,
            },
            icon: {
                type: [String, Boolean],
                required: true,
                validator: (value) => ['success', 'info', 'error'].includes(value),
            },
            classes: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                isFadingOut: false,
                FADE_OUT_DURATION: 500,
            };
        },
        computed: {
            durationSeconds() {
                return this.duration * 1000;
            },
            iconClass() {
                if (this.icon === 'success') {
                    return {
                        class: 'notification__icon--success',
                        viewBox: '-2 0 14 8',
                        path: `M9.707.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2.5-2.5a1 1 0 011.414-1.414L4 5.086
                               8.293.793a1 1 0 011.414 0z`,
                    };
                } else if (this.icon === 'info') {
                    return {
                        class: 'notification__icon--info',
                        viewBox: '-4 0 12 10',
                        path: `M0 4a1 1 0 011-1h1a1 1 0 011 1v4a1 1 0 110 2H2a1 1 0 01-1-1V5a1 1 0 01-1-1z M3 1a1 1 0
                               11-2 0 1 1 0 012 0z`,
                    };
                } else if (this.icon === 'error') {
                    return {
                        class: 'notification__icon--error',
                        viewBox: '-3 0 14 8',
                        path: `M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311
                               0.683417 -0.0976311 1.31658 0.292893 1.70711L2.58579 4L0.292894 6.29289C-0.0976309
                               6.68342 -0.0976309 7.31658 0.292894 7.70711C0.683417 8.09763 1.31658 8.09763 1.70711
                               7.70711L4 5.41421L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711C8.09763
                               7.31658 8.09763 6.68342 7.70711 6.29289L5.41421 4L7.70711 1.70711C8.09763 1.31658 8.09763
                               0.683417 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L4
                               2.58579L1.70711 0.292893Z`,
                    };
                }

                return null;
            },
        },
        methods: {
            hideNotification() {
                this.isFadingOut = true;
                setTimeout(() => {
                    this.isFadingOut = false;
                    this.$emit('hide');
                }, this.FADE_OUT_DURATION);
            },
        },
        mounted() {
            if (this.durationSeconds > 0) {
                setTimeout(() => {
                    this.hideNotification();
                }, this.durationSeconds);
            }
        },
    };
</script>
