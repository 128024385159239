const tooltipBubbles = document.querySelectorAll('.infoBubble, [data-tooltipbubble]');
if (tooltipBubbles.length) {
    tooltipBubbles.forEach(tooltipBubble => {
        tooltipBubble.addEventListener('click', event => {
            const target = event.currentTarget;
            const checkTooltip = target.querySelector('.showTooltip');
            const overlay = document.querySelector('#js--tooltipOverlay');
            if (overlay) {
                overlay.remove();
            }
            if (checkTooltip) {
                checkTooltip.remove();
            } else {
                const showTooltip = document.querySelector('.showTooltip');
                if (showTooltip) {
                    showTooltip.remove();
                }
                // Create Tooltip
                const title = target.dataset.title;
                const text = target.dataset.text;
                const img = target.dataset.img;
                const html = target.dataset.html;
                const position = target.dataset.position;

                const closeButton = document.createElement('button');
                closeButton.classList.add('showTooltip__closeButton');
                closeButton.innerHTML = '&times;';

                const inner = document.createElement('div');
                inner.classList.add('showTooltip');
                inner.setAttribute('role', 'tooltip');
                if (position === 'left') {
                    inner.classList.add('showTooltip--left');
                }
                if (title) {
                    const titleElement = document.createElement('div');
                    titleElement.classList.add('font-size-m');
                    titleElement.classList.add('margin-bottom-7');
                    titleElement.innerText = title;
                    inner.appendChild(titleElement);
                }
                if (img) {
                    const imgElement = document.createElement('img');
                    imgElement.classList.add('showTooltip__image');
                    imgElement.src = img;
                    inner.appendChild(imgElement);
                }
                if (html) {
                    const htmlElement = document.createElement('p');
                    htmlElement.innerHTML = html;
                    inner.appendChild(htmlElement);
                }
                if (text) {
                    const textElement = document.createElement('p');
                    textElement.classList.add('font-size-13px');
                    textElement.classList.add('text-normal');
                    textElement.innerHTML = text;
                    inner.appendChild(textElement);
                }
                inner.appendChild(closeButton);
                setTimeout(() => {
                    target.appendChild(inner);
                }, 0);
            }
        });
    });
}
