const routeElement = document.getElementById('gtm_routeName');
const routeElementData = routeElement.getAttribute('data-page');
const userProductElement = document.getElementById('gtm_userProductGroup');
const userProductElementData = userProductElement.getAttribute('data-page');
const accountTypeIdElement = document.getElementById('gtm_accountTypeId');
const accountTypeIdElementData = accountTypeIdElement.getAttribute('data-page');
const initialLocalStorageObj = {
    'route': routeElementData.toLowerCase(),
    'accountBapOrAnonymous': userProductElementData,
    'accountTypeId': accountTypeIdElementData,
};

function setLocalStorage() {
    window.addEventListener('load', () => {
        const singleAccountNavItem = document.querySelector('[data-user-journey="accountsOverviewPages"]');
        const corporateAccountNavItem = document.querySelector('[data-user-journey="corporateAccount"]');
        const pricesMainNavItem = document.querySelector('[data-user-journey="pricesMainNav"]');
        const navItems = [singleAccountNavItem, corporateAccountNavItem, pricesMainNavItem];

        const routes = ['accountsOverviewPages', 'accountRequestPrefilled', 'accountRequest'];
        const excludedRoutes = routes.some(route => routeElementData.includes(route));
        if (!excludedRoutes) {
            saveToLocaleStorage();
            const popupCtaElements = document.querySelectorAll('[data-user-journey*="PopupCta"]');
            popupCtaElements.forEach(popup => {
                if (popup) {
                    popup.addEventListener('click', () => {
                        saveToLocaleStorage(popup.dataset.userJourney, 'clicked');
                    });
                }
            });
            navItems.forEach(element => {
                if (element) {
                    element.addEventListener('click', event => {
                        const value = event.target.dataset.userJourney;
                        saveToLocaleStorage('navigation', value);
                    });
                }
            });
            const sourceItems = document.querySelectorAll('[data-user-journey="sourcesLink"]');
            if (sourceItems.length) {
                sourceItems.forEach(source =>
                    source.addEventListener('click', () => {
                        saveLastClickedOnStatistic('lastClickedOnStatistic', 'source-click');
                    })
                );
            }
            if (document.getElementById('gtm_stat_accessType')) {
                const userStatAccessData = document.getElementById('gtm_stat_accessType');
                const userStatAccessElementData = userStatAccessData.getAttribute('data-page');
                saveToLocaleStorage('statisticPaidOrFree', userStatAccessElementData);
            }
            if (document.getElementById('gtm_study_id')) {
                const lastSeenStudyImage = document.querySelector("meta[property='og:image']").getAttribute('content');
                saveToLocaleStorage('studyCoverUrl', lastSeenStudyImage);
            }
        }
    });
}

function saveToLocaleStorage(key, value) {
    if (key) {
        initialLocalStorageObj[key] = value;
    }
    window.localStorage.setItem('userJourney', JSON.stringify(initialLocalStorageObj));
}

function saveLastClickedOnStatistic(key, value) {
    saveToLocaleStorage(key, value);
    saveToLocaleStorage('lastClickedOnStatistic', value);
}

export { setLocalStorage, saveLastClickedOnStatistic };
