var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notification",
      class: [{ "notification--fadeOut": _vm.isFadingOut }, _vm.classes],
    },
    [
      _c("div", { staticClass: "notification__wrapper" }, [
        _vm.iconClass
          ? _c(
              "div",
              { staticClass: "notification__icon", class: _vm.iconClass.class },
              [
                _c(
                  "svg",
                  {
                    staticClass: "stat__svg--notification",
                    attrs: {
                      viewBox: _vm.iconClass.viewBox,
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      "aria-hidden": "true",
                    },
                  },
                  [_c("path", { attrs: { d: _vm.iconClass.path } })]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "notification__content" }, [
          _vm.$slots.title
            ? _c(
                "h3",
                { staticClass: "notification__title" },
                [_vm._t("title")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "notification__text" }, [_vm._t("text")], 2),
        ]),
      ]),
      _vm._v(" "),
      _c("span", {
        staticClass: "notification__close",
        on: { click: _vm.hideNotification },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }