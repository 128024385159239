import { isMobile } from './isMobile';
import { UNVEIL_EVENT } from './lazyLoader/lazyLoader';

// Simple accordion toggler with slide animation
// example HTML:

// <div data-accordion="{'hideOthers': false, 'lazyClass': 'lazy'}">
//     <h4 data-accordioncontent=".content1">titel 1</h4>
//     <div class="content1">mein text hier </div>
//     <h4 data-accordioncontent=".content2">titel 1</h4>
//     <div class="content2">mein text hier </div>
// </div>

const ENTER_KEY_CODE = 13;

function initAccordion() {
    document.querySelectorAll('[data-accordion]').forEach(accordion => {
        const wrapper = accordion;
        let customSettings = {};

        try {
            customSettings = parseCustomSettings(wrapper);
        } catch (error) {
            console.warn('error parsing json configuration: ', error);
        }

        let hideOthers = true;
        if (typeof customSettings.hideOthers !== 'undefined') {
            hideOthers = (customSettings.hideOthers === 'desktop') ? !isMobile() : (customSettings.hideOthers === 'mobile') ? isMobile() : customSettings.hideOthers;
        }

        let disableActiveTab = false;
        if (typeof customSettings.disableActiveTab !== 'undefined') {
            disableActiveTab = (customSettings.disableActiveTab === 'desktop') ? !isMobile() : (customSettings.disableActiveTab === 'mobile') ? isMobile() : customSettings.disableActiveTab;
        }

        const _settings = {
            hideOthers: hideOthers,
            disableActiveTab: disableActiveTab,
            lazyClass: typeof customSettings.lazyClass !== 'undefined' ? customSettings.lazyClass : '.lazy',
            openOnMobile: typeof customSettings.openOnMobile !== 'undefined' ? customSettings.openOnMobile : null,
            onlyViewport: typeof customSettings.onlyViewport !== 'undefined' ? customSettings.onlyViewport : null,
            initOpen: typeof customSettings.initOpen !== 'undefined' ? customSettings.initOpen : null,
            fadeInOut: typeof customSettings.fadeInOut !== 'undefined' ? customSettings.fadeInOut : true,
        };

        const enableAccordion = (_settings.onlyViewport === 'desktop' && !isMobile() ||
                               _settings.onlyViewport === 'mobile' && isMobile() ||
                               !_settings.onlyViewport);


        if (enableAccordion) {
            const hiddenClass = (_settings.onlyViewport === 'mobile' && isMobile()) ? 'hidden-s' : 'hidden';

            if (isMobile() && !isNaN(_settings.openOnMobile)) {
                resetActive(wrapper, _settings);
            }
            if (_settings.initOpen) {
                resetActive(wrapper, _settings);
                const openElements = wrapper.querySelectorAll(_settings.initOpen);
                if (openElements.length) {
                    openElements.forEach(element => {
                        triggerActive(element, wrapper, hiddenClass, _settings);
                    });
                }
            }
            wrapper.querySelectorAll('[data-accordioncontent]').forEach(element => {
                if (element.classList.contains('active')) {
                    element.setAttribute('aria-expanded', 'true');
                } else {
                    element.setAttribute('aria-expanded', 'false');
                }

                element.addEventListener('click', event => {
                    triggerActive(event.currentTarget, wrapper, hiddenClass, _settings);
                });
                element.addEventListener('keydown', event => {
                    if (event.keyCode === ENTER_KEY_CODE) {
                        event.preventDefault();
                        triggerActive(event.currentTarget, wrapper, hiddenClass, _settings);
                    }
                });
                element.addEventListener('toggleAccordion', event => {
                    triggerActive(event.currentTarget, wrapper, hiddenClass, _settings);
                });
            });
        }
    });

    function parseCustomSettings(wrapper) {
        const settingsString = JSON.parse(wrapper.dataset.accordion.replace(/'/g, '"'));

        return settingsString || undefined;
    }

    function triggerActive(element, wrapper, hiddenClass, _settings) {
        const accordionContent = wrapper.querySelector(element.dataset.accordioncontent);
        if (accordionContent) {
            accordionContent.classList.add('animated');

            if (!element.classList.contains('active')) {
                const lazyItems = accordionContent.querySelectorAll(_settings.lazyClass);

                if (_settings.hideOthers) {
                    hideOtherContents(wrapper, hiddenClass, _settings);
                }
                // Show Accordion Content
                accordionContent.style.removeProperty('display');
                element.setAttribute('aria-expanded', 'true');
                element.classList.add('active');
                if (_settings.fadeInOut) {
                    accordionContent.classList.remove('fadeOut');
                }
                accordionContent.classList.remove(hiddenClass);
                accordionContent.classList.add('display-block');
                if (_settings.fadeInOut) {
                    accordionContent.classList.add('fadeInDown');
                }
                accordionContent.classList.add('accordion--active');
                if (lazyItems) {
                    lazyItems.forEach(element => {
                        element.dispatchEvent(UNVEIL_EVENT);
                        element.classList.remove('lazy');
                    });
                }
                if (_settings.disableActiveTab) {
                    element.setAttribute('disabled', 'disabled');
                }
            } else {
                hideAccordionContent(accordionContent, element, hiddenClass, _settings);
            }
        }
    }

    function hideOtherContents(wrapper, hiddenClass, _settings) {
        wrapper.querySelectorAll('[data-accordioncontent]').forEach(element => {
            const accordionContent = wrapper.querySelector(element.dataset.accordioncontent);
            hideAccordionContent(accordionContent, element, hiddenClass, _settings);
            element.removeAttribute('disabled');
        });
    }

    function hideAccordionContent(accordionContent, element, hiddenClass, _settings) {
        accordionContent.style.removeProperty('display');
        element.setAttribute('aria-expanded', 'false');
        element.classList.remove('active');
        if (_settings.fadeInOut) {
            accordionContent.classList.remove('fadeInDown');
            accordionContent.classList.add('fadeOut');
        }
        accordionContent.classList.add(hiddenClass);
        accordionContent.classList.remove('display-block');
        accordionContent.classList.remove('accordion--active');
    }

    function resetActive(wrapper, _settings) {
        wrapper.querySelectorAll('[data-accordioncontent]').forEach((element, index) => {
            const content = wrapper.querySelector(element.dataset.accordioncontent);

            if (index < parseInt(_settings.openOnMobile)) {
                element.classList.add('active');
                content.classList.add('active');
            } else {
                element.classList.remove('active');
                content.classList.remove('active');
            }
        });
    }
}

export { initAccordion };
