<template>
    <div class="notifications">
        <div
            v-for="(notificationGroup, groupKey) in notifications"
            :key="groupKey"
            :class="`notifications__${ groupKey }`">
            <notification
                v-for="(notification) in notificationGroup"
                :key="notification.id"
                :duration="notification.duration"
                :icon="notification.icon"
                :classes="notification.classes"
                :class="`notification--${ notification.position }`"
                @hide="removeNotification(notification, groupKey)">
                <span
                    v-if="notification.title"
                    slot="title">{{ notification.title }}</span>
                <span slot="text">{{ notification.text }}</span>
            </notification>
        </div>
    </div>
</template>

<script>
    import notification from './notification';

    export default {
        components: { 'notification': notification },
        data() {
            return {
                notifications: {
                    bottomLeft: [],
                    bottomRight: [],
                    topLeft: [],
                    topRight: [],
                },
                DEFAULT_POSITION: 'bottomRight',
                DEFAULT_ICON: 'success',
                DEFAULT_TITLE: null,
                DEFAULT_TEXT: '',
                DEFAULT_DURATION: 3,
                DEFAULT_CLASSES: '',
            };
        },
        methods: {
            removeNotification(notification, groupKey) {
                for (let i = 0; i < this.notifications[groupKey].length; i++) {
                    if (this.notifications[groupKey][i] === notification) {
                        this.notifications[groupKey].splice(i, 1);
                    }
                }
            },
            prepareSettings(data) {
                return {
                    icon: data.icon || this.DEFAULT_ICON,
                    text: data.text || this.DEFAULT_TEXT,
                    title: data.title ? data.title : this.DEFAULT_TITLE,
                    duration: data.duration ? data.duration : this.DEFAULT_DURATION,
                    position: data.position || this.DEFAULT_POSITION,
                    id: this.getTimestamp(),
                    classes: data.classes || this.DEFAULT_CLASSES,
                };
            },
            getTimestamp() {
                return new Date().getTime();
            },
        },
        created() {
            this.$root.$on('message', data => {
                let preparedData;

                if (typeof data === 'object') {
                    preparedData = this.prepareSettings(data);
                } else if (typeof data === 'string') {
                    preparedData = this.prepareSettings({ text: data });
                }

                this.notifications[preparedData.position].push(preparedData);
            });
        },
    };
</script>
