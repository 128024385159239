import { createCookie, getCookie, currentDomain } from 'tools/cookies';
import { OneTrustCookieRepository } from './OneTrustCookieRepository';

const DEFAULT_COOKIE_EXPIRATION_DAYS = 10;
const TESTING_BYPASS_KEY = 'automatedTest';
const TESTING_BYPASS_VALUE_ENABLED = 1;
const EXPIRY_ONE_YEAR = 365;
const JSON_PROP_COOKIES = 'c';
const JSON_PROP_GROUPS = 'g';
const JSON_PROP_HOSTS = 'h';
const JSON_PROP_ERROR_MESSAGE = 'Root object cookie is missing!';
const ACCEPTANCE_RESPONSE_ON_MISSING_API_DATA = true;

class SafeCookie {
    /**
     * @param {string} _name
     * @param {*} _value
     * @param {number} _days
     * @param {function} _currentDomain
     * @param {function} _createCookie
     * @param {NewableFunction} _groups
     */
    constructor(
        _name,
        _value,
        _days = DEFAULT_COOKIE_EXPIRATION_DAYS,
        _currentDomain = currentDomain,
        _createCookie = createCookie,
        _groups = OneTrustCookieRepository,
    ) {
        this.name = _name;
        this.value = _value;
        this.days = _days;
        this.currentDomain = _currentDomain;
        this.createCookie = _createCookie;
        this.groups = new _groups();
        this.groupsAndHosts = this.importGroupsAndHosts();
    }

    isTesting() {
        const isTesting = window.location.search.indexOf(TESTING_BYPASS_KEY) > -1;
        if (isTesting) {
            this.createCookie(TESTING_BYPASS_KEY, TESTING_BYPASS_VALUE_ENABLED, EXPIRY_ONE_YEAR, this.currentDomain());
        }

        return getCookie(TESTING_BYPASS_KEY) === TESTING_BYPASS_VALUE_ENABLED;
    }

    /**
     *
     * @returns {{error: string}|*}
     */
    importGroupsAndHosts() {
        try {
            return JSON.parse(document.querySelector('[data-onetrust-conf]')
                .dataset.onetrustConf)[JSON_PROP_COOKIES] ?? { 'error': JSON_PROP_ERROR_MESSAGE };
        } catch (error) {
            return {
                'error': error.toString(),
            };
        }
    }

    /**
     * @param {string} name
     *
     * @returns {boolean}
     */
    accepted(name) {
        if (Object.keys(this.groupsAndHosts).length <= 1 && Object.prototype.hasOwnProperty.call(this.groupsAndHosts, 'error')) {
            return ACCEPTANCE_RESPONSE_ON_MISSING_API_DATA;
        }

        if (!Object.prototype.hasOwnProperty.call(this.groupsAndHosts, name)) {
            return false;
        }

        return this.groups.acceptedByGroups(this.groupsAndHosts[name][JSON_PROP_GROUPS] ?? []) ||
            this.groups.acceptedByHosts(this.groupsAndHosts[name][JSON_PROP_HOSTS] ?? []);
    }

    /**
     * @returns void
     */
    save() {
        if (!this.accepted(this.name) && !this.isTesting()) {
            return;
        }
        this.createCookie(this.name, this.value, this.days, this.currentDomain());
    }
}

export { SafeCookie };
