import { deepmerge } from 'tools/deepmerge';
import isLocalStorageSupported from './localStorageSupported';

function displayAllowed(selector, settings = {}) {
    const element = selector.nodeType ? selector : document.querySelector(selector);

    if (!element.nodeType || !isLocalStorageSupported()) {
        // element not recognized OR local storage not supported
        return false;
    }

    const defaults = {
        countUpOnShow: false,
        maxCountOnClose: false,
        startFrom: 1,
        closeElement: ['.close', '.modal-backdrop', '.vueModal__close', '.vueModal__overlay', '[data-close-modal]'],
    };
    const config = deepmerge(defaults, settings);

    const elementIdentifier = element.getAttribute('id');

    const showCount = config.showCount ? config.showCount : (parseInt(element.dataset.showCount) || 100000);
    const showTimeLimit = config.showTimeLimit ? config.showTimeLimit : parseInt(element.dataset.showLimit) || undefined;

    const combinedCloseSelector = config.closeElement.join(', ');

    const localStorageShowsMaximumKey = [elementIdentifier, 'showPerUserValue'].join('/');
    const localStorageShowsUsedKey = [elementIdentifier, 'showPerUserUsed'].join('/');
    const localStorageShowsLimitDateKey = [elementIdentifier, 'showLimitDate'].join('/');

    if (popupCountHasChanged() || valuesUndefined()) {
        // reset keys
        window.localStorage[localStorageShowsMaximumKey] = showCount;
        window.localStorage[localStorageShowsUsedKey] = 0;
    }
    if (typeof showTimeLimit !== 'undefined' && typeof window.localStorage[localStorageShowsLimitDateKey] === 'undefined') {
        initTimeLimitTimestamp();
    }
    if (timeLimitReached()) {
        window.localStorage[localStorageShowsUsedKey] = 0;
        initTimeLimitTimestamp();
    }

    function initTimeLimitTimestamp() {
        const timeLimitDate = new Date();
        const timeLimitTimestamp = timeLimitDate.setDate(timeLimitDate.getDate() + parseInt(showTimeLimit));
        window.localStorage[localStorageShowsLimitDateKey] = timeLimitTimestamp;
    }

    function popupCountHasChanged() {
        return parseInt(window.localStorage[localStorageShowsMaximumKey]) !== showCount;
    }

    function valuesUndefined() {
        const _localShowsValue = window.localStorage[localStorageShowsMaximumKey];
        const _localShowsLeftValue = window.localStorage[localStorageShowsUsedKey];

        return typeof _localShowsValue === 'undefined' || typeof _localShowsLeftValue === 'undefined';
    }

    function displayCountUp() {
        let countedUpValue = window.localStorage[localStorageShowsUsedKey];
        const currentValue = countedUpValue;

        if (currentValue <= showCount && typeof currentValue !== 'undefined') {
            countedUpValue = window.localStorage[localStorageShowsUsedKey] = (parseInt(currentValue) + 1);
        } else {
            countedUpValue = window.localStorage[localStorageShowsUsedKey];
        }

        return parseInt(countedUpValue);
    }

    function setDisplayMaximum(showCount) {
        window.localStorage[localStorageShowsUsedKey] = showCount;
    }

    function timeLimitReached() {
        const timeLimitTimestamp = window.localStorage[localStorageShowsLimitDateKey];
        if (typeof timeLimitTimestamp === 'undefined') {
            return false;
        }

        return typeof showTimeLimit !== 'undefined' && Date.now() > parseInt(timeLimitTimestamp);
    }

    function getShowModalAllowed() {
        return window.localStorage[localStorageShowsUsedKey] <= showCount;
    }

    let showModal;

    if (config.countUpOnShow && config.maxCountOnClose === false) {
        // example: show element 10 times
        showModal = getShowModalAllowed();
        displayCountUp();
    } else if (config.countUpOnShow && config.maxCountOnClose === true) {
        // example: show 10 times, but hide permanently if closed
        showModal = getShowModalAllowed();
        displayCountUp();
        const closeElements = element.querySelectorAll(combinedCloseSelector);
        if (closeElements) {
            closeElements.forEach(closeElement => closeElement.addEventListener('click', () => {
                setDisplayMaximum(showCount);
                element.style.display = 'none';
            }));
        }
    } else {
        // example: always show, user have to explicitly close 2 times
        // also supports modal-close event (hide.bs.modal) (click on overlay works)
        if (matches(element, '.modal') && !element.hasAttribute('data-vue-modal')) {
            // TODO: count up on vue modal close event since bootstrap close event is not used
        } else {
            const closeElements = element.querySelectorAll(combinedCloseSelector);
            if (closeElements) {
                closeElements.forEach(closeElement => closeElement.addEventListener('click', () => {
                    displayCountUp();
                }));
            }
            element.parentElement.addEventListener('click', event => {
                if (matches(event.target, combinedCloseSelector) || event.target.closest(combinedCloseSelector)) {
                    displayCountUp();
                }
            });
        }
        showModal = getShowModalAllowed();
    }

    return showModal;
}

function matches(el, selector) {
    return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
}

export { displayAllowed };
