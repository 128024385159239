/**
 * FloatLabels
 * URL: http://clubdesign.github.io/floatlabels.js/
 * Author: Marcus Pohorely ( http://www.clubdesign.at )
 * Copyright: Copyright 2013 / 2014 http://www.clubdesign.at
 *
 * Adapted for bootstrap projects by Michael Levin 2/20/14
 */

import deepmerge from 'tools/deepmerge';

const DEFAULTS = {
    labelStartTop: '0px',
    labelEndTop: '10px',
    paddingOffset: '16px',
    transitionEasing: 'ease-in-out',
    typeMatches: /text|password|email|number|search|url/,
};

class Floatlabel {
    constructor(element, options = {}) {
        this.element = element;
        this.settings = deepmerge(DEFAULTS, options);
        this.init();
    }

    init() {
        const settings = this.settings,
            thisElement = this.element;

        if (thisElement.tagName !== 'INPUT') { return; }
        if (!settings.typeMatches.test(thisElement.getAttribute('type'))) { return; }

        let elementID = thisElement.getAttribute('id');
        if (!elementID) {
            elementID = Math.floor(Math.random() * 100) + 1;
            thisElement.setAttribute('id', elementID);
        }
        let placeholderText = thisElement.getAttribute('placeholder');
        let floatingText = thisElement.dataset.label;
        if (!placeholderText || placeholderText === '') { placeholderText = ''; }
        if (!floatingText || floatingText === '') { floatingText = placeholderText; }

        const hasFloatlabelParent = thisElement.parentNode.hasAttribute('data-datalabel-wrapper');

        if (!hasFloatlabelParent) {
            this.inputPaddingTop = parseFloat(getComputedStyle(thisElement).paddingTop) + parseFloat(settings.paddingOffset);
            this.wrapInput(thisElement);
            this.insertLabel(thisElement, elementID, floatingText);
            thisElement.style.paddingTop = `${ this.inputPaddingTop }px`;
        } else {
            this.label = thisElement.previousElementSibling.nodeName === 'LABEL' ? thisElement.previousElementSibling : null;
        }
        thisElement.addEventListener('keyup', event => {
            this.checkValue(event);
        });
        thisElement.addEventListener('blur', event => {
            this.checkValue(event);
        });
        thisElement.addEventListener('change', event => {
            this.checkValue(event);
        });
        thisElement.addEventListener('blur', () => {
            const label = thisElement.parentNode.querySelector(`[for="${ thisElement.getAttribute('id') }"]`);
            label.style.color = '#808080';
        });
        thisElement.addEventListener('focus', () => {
            const label = thisElement.parentNode.querySelector(`[for="${ thisElement.getAttribute('id') }"]`);
            label.style.color = '#54a9ed';
        });
        window.setTimeout(() => this.label.classList.add('floatlabel__label'), 100);
        this.checkValue();
    }

    wrapInput(inputElement) {
        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute('data-datalabel-wrapper', 'true');
        wrapperElement.style.position = 'relative';
        wrap(inputElement, wrapperElement);
    }

    insertLabel(inputElement, elementID, floatingText) {
        const labelElement = document.createElement('label');
        labelElement.setAttribute('for', elementID);
        labelElement.innerText = floatingText;
        inputElement.parentNode.insertBefore(labelElement, inputElement);
        this.label = labelElement;
    }

    checkValue(e) {
        if (e) {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 9) { return; }
        }
        const thisElement = this.element,
            currentFlout = thisElement.dataset.flout;
        if (thisElement.value !== '') {
            thisElement.dataset.flout = '1';
        }
        if (thisElement.value === '') {
            thisElement.dataset.flout = '0';
        }
        if (thisElement.dataset.flout === '1' && currentFlout !== '1') {
            this.showLabel();
        }
        if (thisElement.dataset.flout === '0' && currentFlout !== '0') {
            this.hideLabel();
        }
    }

    showLabel() {
        this.label.style.display = 'block';
        window.setTimeout(() => {
            this.label.classList.remove('floatlabel__label--off');
            this.label.classList.add('floatlabel__label--on');
        }, 50);
    }

    hideLabel() {
        this.label.classList.remove('floatlabel__label--on');
        this.label.classList.add('floatlabel__label--off');
        window.setTimeout(() => {
            this.label.display = 'none';
        }, 100);
    }
}

function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

function floatlabels(selector) {
    const floatInputs = document.querySelectorAll(selector);
    if (floatInputs) {
        floatInputs.forEach(input => new Floatlabel(input));
    }
}

export { Floatlabel, floatlabels };
