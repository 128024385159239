/* global console */ // eslint-disable-line no-redeclare
import axios from 'axios';
import uriEncode from 'tools/uriEncode';
import Translator from 'tools/trans';
import { getCookie, deleteCookie } from 'tools/cookies';
import { SafeCookie } from 'tools/cookies/SafeCookie';
import { toSeoString } from 'tools/seoString';
import { pushToDataLayer } from 'search/dataLayer';

const searchUrls = {
    en: '/search/',
    de: '/statistik/suche/',
    es: '/buscar/',
    fr: '/recherche/',
};
function getSearchUrl(currentLocal) {
    return searchUrls[currentLocal];
}
const searchUrl = getSearchUrl(window.stGlobals.locale);

const intervalLabels = {
    'FORM_SEARCH_ALL': Translator.trans('FORM_SEARCH_ALL'),
    'FORM_SEARCH_LAST_MONTH': Translator.trans('FORM_SEARCH_LAST_MONTH'),
    'FORM_SEARCH_OLDER': Translator.trans('FORM_SEARCH_OLDER'),
};
const categoryLabels = {
    'FORM_SEARCH_ALL': Translator.trans('FORM_SEARCH_ALL'),
};
const cacheStateValidEntries = ['-1', '0', '1'];
const PREBUILT_URL = 'PREBUILT_URL';
const EXPIRATION_YEAR = 365;
const EXPIRATION_DAY = 1;
export const defaultLanguageValue = {
    en: '0',
    de: '1',
    fr: '3',
    es: '4',
};
// constants for entities
export const ent = {
    STUDY: 'study',
    FORECAST: 'forecast',
    DOSSIER: 'dossier',
    DOSSIERPLUS: 'dossierplus',
    XMO: 'xmo',
    SURVEY: 'survey',
    TOPLIST: 'toplist',
    TOPIC: 'topic',
    STATISTIC: 'statistic',
    INFOGRAPHIC: 'infographic',
    COMPANYREPORT: 'companyreport',
    ECOMMERCEDB: 'ecommercedb',
    ECOMMERCEDBGROUP: 'eCommerce Insights',
    BRANDREPORT: 'brandreport',
    BRANCHREPORT: 'branchreport',
    CMO: 'cmo',
    DMO: 'dmo',
    MMO: 'mmo',
    CO: 'co',
    TMO: 'tmo',
    AMO: 'amo',
    IO: 'io',
    HMO: 'hmo',
    FMO: 'fmo',
    EMO: 'emo',
    GCS: 'gcs',
};


export const pathExt = {
    ACCOUNTS: '/accounts/ca',
    PROMO: '/accounts/pa',
};
const uri = window.location.search.substring(1);
const URLSearchParams = window.URLSearchParams;
const uparams = new URLSearchParams(uri);
// search parameter for different requests
const parPureStatistics = {
        statistics: 1,
    },
    parReports = {
        dossiers: 1,
        dossiersplus: 1,
        branchreports: 1,
        countryreports: 1,
        cityreports: 1,
        ecommercedbreports: 1,
        xmo: 1,
        companyreports: 1,
        brandreports: 1,
        toplists: 1,
        surveys: 1,
        groupA: 1,
        groupB: 1,
        groupC: 1,
        groupD: 1,
    },
    parReportsLimit = {
        resultLimit: 5,
        ...parReports,
    },
    parAll = {

    },
    parMarketInsights = {
        cmo: 1,
        mmo: 1,
        co: 1,
        tmo: 1,
        amo: 1,
        io: 1,
        hmo: 1,
        dmo: 1,
        fmo: 1,
        emo: 1,
    },
    parInfographic = {
        infos: 1,
    },
    parForecast = {
        forecasts: 1,
    },
    parECommerceInsights = {
        ecommercedb: 1,
    },
    parConsumerInsights = {
        gcs: 1,
        brandreports: 1,
        surveys: 1,
    },
    parConsumerInsightsWithoutGCS = {
        brandreports: 1,
        surveys: 1,
    },
    parTopics = {
        topics: 1,
    },
    parTopicsLimit = {
        topics: 1,
        resultLimit: 5,
    };
const entityPar = {
    statistic: parPureStatistics,
    report: parReports,
    topic: parTopics,
    market: parMarketInsights,
    infographic: parInfographic,
    forecast: parForecast,
    gcs: window.stGlobals.user ? parConsumerInsights : parConsumerInsightsWithoutGCS,
    ecommercedb: parECommerceInsights,
};
// filter for entities
const entitiesStat = [
    { name: 'statistic', par: 'statistics', path: 'statistic', id: 1 },
    { name: 'forecast', par: 'forecasts', path: 'forecast', id: 8 },
];
const entitiesRepo = [
    { name: 'dossier', par: 'reports', path: 'study', id: 16 },
    { name: 'dossierplus', par: 'reports', path: 'study', id: 36 },
    { name: 'xmo', par: 'reports', path: 'study', id: 20 },
    { name: 'branchreport', par: 'reports', path: 'study', id: 17 },
    { name: 'countryreport', par: 'reports', path: 'study', id: 22 },
    { name: 'cityreport', par: 'reports', path: 'study', id: 40 },
    { name: 'ecommercedbreport', par: 'reports', path: 'study', id: 44 },
    { name: 'ecommercedbreport', par: 'reports', path: 'study', id: 45 },
    { name: 'companyreport', par: 'reports', path: 'study', id: 42 },
    { name: 'brandreport', par: 'reports', path: 'study', id: 38 },
    { name: 'toplist', par: 'reports', path: 'study', id: 18 },
    { name: 'survey', par: 'reports', path: 'study', id: 19 },
];
const entitiesMarketInsights = [
    { name: 'cmo', par: 'outlooks', path: 'outlook', id: 14 },
    { name: 'dmo', par: 'outlooks', path: 'outlook', id: 11 },
    { name: 'mmo', par: 'outlooks', path: 'outlook', id: 23 },
    { name: 'co', par: 'outlooks', path: 'outlook', id: 24 },
    { name: 'tmo', par: 'outlooks', path: 'outlook', id: 25 },
    { name: 'amo', par: 'outlooks', path: 'outlook', id: 27 },
    { name: 'io', par: 'outlooks', path: 'outlook', id: 28 },
    { name: 'hmo', par: 'outlooks', path: 'outlook', id: 50 },
    { name: 'fmo', par: 'outlooks', path: 'outlook', id: 51 },
    { name: 'emo', par: 'outlooks', path: 'outlook', id: 52 },
];
const allEntities = [ ...entitiesRepo, ...entitiesStat, ...entitiesMarketInsights, { name: 'infographic', par: 'infographics', path: 'infographic', id: 5 },
    { name: 'topic', par: 'topics', path: 'topic', id: 3 }, { name: 'study', par: 'reports', path: 'study', id: 4 },
    { name: 'marketstudy', par: 'reports', path: 'study', id: 21 },
    { name: 'ecommercedb', par: 'ecommercedb', path: 'ecommercedb', id: 54 }, { name: 'ecommercedb', par: 'ecommercedb', path: 'ecommercedb', id: 55 }];
const CONSUMER = 'consumer';
const MARKET = 'market';
const GCS = 'gcs';
const GCSREPORT = 'gcsReports';
const DEFAULT = 'default';
const EMPTY_ARRAY = [];

// mix parameter with default
function getPar(state, rootGetters, par, filterParams = false) {
    let param = !par.resultLimit ? {
        q: state.query,
        asJsonResponse: 1,
        p: state.currentPage,
        ...par,
    } : {
        q: state.query,
        asJsonResponse: 1,
        p: state.currentPage,
        resultOffset: (5 * state.currentPage) - 5,
        ...par,
    };
    if (cacheStateValidEntries.includes(state.cacheState)) {
        param['cacheState'] = state.cacheState;
    }
    if (filterParams) {
        return param;
    }
    if (state.sort) {
        param.sortMethod = state.sort;
    }
    if (state.focus) {
        param.isRegionPref = state.focus;
    }
    if (state.location.length > 0) {
        if (typeof state.location === 'string') {
            param['region'] = state.location.split(',');
        } else {
            param['region'] = state.location;
        }
    }
    if (state.isoregion) {
        param['isoregion'] = state.isoregion;
    }
    if (state.tags.length > 0) {
        param['tags'] = state.tags;
    }
    if (state.tabGroup.length > 0) {
        state.tabGroup.forEach(group => {
            param = { ...param, ...entityPar[group] };
        });
    }
    if (state.interval) {
        param['interval'] = state.interval.join(',');
    }
    if (state.accuracy) {
        param['accuracy'] = state.accuracy;
    }
    if (state.idRelevance) {
        param['idRelevance'] = state.idRelevance;
    }
    if (state.language) {
        param['language'] = state.language;
    }
    if (state.category) {
        if (state.category !== '0') {
            param['category[]'] = state.category.join(',');
        }
    }
    if (state.subCategory) {
        if (state.subCategory !== '0') {
            param['subCategory[]'] = state.subCategory.join(',');
        }
    }
    if (state.deactivateOldFieldRate) {
        param['deactivateOldFieldRate'] = state.deactivateOldFieldRate;
    }
    if (state.deactivateOldFilterScore) {
        param['deactivateOldFilterScore'] = state.deactivateOldFilterScore;
    }

    return param;
}

// set parameter in browser history
function setParam(par) {
    const
        url = new URL(location),
        param = {};
    par.forEach(item => {
        if (item.name === 'tags[]'&& uparams.get('tags[]')) {
            return;
        } else {
            url.searchParams.set(item.name, item.value);
        }
        param[ item.name ] = item.value;
    });
    history.pushState(param, '', url);
}

// concating outlook translations
function buildOutlookTitle(marketName, outlook) {
    const xmoTitleTransMapping = {
        cmo: Translator.trans('NAVIGATION_CMO'),
        dmo: Translator.trans('NAVIGATION_DMO'),
        mmo: Translator.trans('NAVIGATION_MMO'),
        co: Translator.trans('NAVIGATION_CO'),
        tmo: Translator.trans('NAVIGATION_TMO'),
        amo: Translator.trans('NAVIGATION_AMO'),
        io: Translator.trans('NAVIGATION_IO'),
        hmo: Translator.trans('NAVIGATION_HMO'),
        emo: Translator.trans('NAVIGATION_EMO'),
        fmo: Translator.trans('NAVIGATION_FMO'),
    };

    return `${ marketName } | ${ xmoTitleTransMapping[outlook] }`;
}

function buildOutlookDescription(market, preposition) {
    return Translator.trans('SEARCH_RESULT_XMO_SUBTITLE').replace('{market}', market).replace('{preposition}', preposition);
}

export const search = {
    namespaced: true,

    state: {
        show: false,
        loading: -1,
        countries: [],
        query: '',
        topics: [],
        currentPage: uparams && uparams.get('p') ? Number(uparams.get('p')) : 1,
        totalFound: 0,
        totalPages: 0,
        entities: [],
        searchArray: [],
        resultArray: [],
        masterTags: [],
        promotedArray: [],
        repoArray: [],
        metrics: {},
        originalMetrics: {},
        paths: {},
        sort: uparams && uparams.get('sortMethod') ? uparams.get('sortMethod') : 'idrelevance',
        focus: '-1',
        intervalData: [],
        branchesData: [],
        interval: uparams && uparams.get('interval') ? uparams.get('interval').split(',') : ['0'],
        accuracy: uparams && uparams.get('accuracy') ? uparams.get('accuracy') : 'and',
        location: uparams && uparams.get('region[]') ? uparams.get('region[]') : [],
        isoregion: uparams && uparams.get('isoregion') ? uparams.get('isoregion') : '0',
        cacheState: uparams && uparams.get('cacheState') ? uparams.get('cacheState') : null,
        category: uparams && uparams.get('category[]') ? uparams.get('category[]').split(',') : ['0'],
        subCategory: uparams && uparams.get('subCategory[]') ? uparams.get('subCategory[]').split(',') : ['0'],
        tags: getCookie('tags') ? getCookie('tags').split(',') : [],
        tabGroup: uparams && uparams.get('tabGroup') ? uparams.get('tabGroup').split(',') : [],
        showSidebar: false,
        idRelevance: uparams && uparams.get('idRelevance') ? uparams.get('idRelevance') : '0',
        language: uparams && uparams.get('language') ? uparams.get('language') : defaultLanguageValue[window.stGlobals.locale],
        regionsData: [],
        isoregionsData: [],
        resetFilterClicked: false,
        applyFilterClicked: false,
        userPermissionToGCS: false,
        deactivateOldFieldRate: uparams && uparams.get('deactivateOldFieldRate') ? Number(uparams.get('deactivateOldFieldRate')) : null,
        deactivateOldFilterScore: uparams && uparams.get('deactivateOldFilterScore') ? Number(uparams.get('deactivateOldFilterScore')) : null,
        response: null,
        histories: [],
        entryUniqueId: null,
        entry: null,
        selectedIndustries: [],
        selectedSubIndustries: [],
        selectedCategories: {},
        activeTab: null,
        selectedFilterNumber: 0,
        bannerInsights: [],
    },

    getters: {
        show: state => state.show,
        loading: state => state.loading,
        countries: state => state.countries,
        idRelevance: state => state.idRelevance,
        language: state => state.language,
        query: state => state.query,
        entity: state => (entityNr) => state.entities?.find(item => item.id === Number(entityNr)),
        entityName: (state, getters) => (entityNr) => {
            const entity = getters.entity(entityNr);

            return entity?.key || 'default';
        },
        getIcon: (state, getters) => (item) => {
            const name = getters.entityName(item.identity || item.idContentType);
            switch (name) {
                case ent.STUDY:
                case ent.DOSSIER:
                case ent.DOSSIERPLUS:
                case ent.XMO:
                case ent.SURVEY:
                case ent.TOPLIST:
                case ent.BRANDREPORT:
                case ent.BRANCHREPORT:
                case ent.COMPANYREPORT:
                    return ent.STUDY + (item.ischarged === '1' || item.ischarged === true ? 'Premium' : 'Basis');
                case ent.FORECAST:
                    return ent.FORECAST + (item.ischarged === '1' || item.ischarged === true ? 'Premium' : 'Basis');
                case ent.TOPIC:
                    return ent.TOPIC;
                case ent.STATISTIC:
                    return ent.STATISTIC + (item.ischarged === '1' || item.ischarged === true ? 'Premium' : 'Basis');
                case ent.INFOGRAPHIC:
                    return 'infographic';
                case ent.CMO:
                    return ent.CMO;
                case ent.DMO:
                    return ent.DMO;
                case ent.MMO:
                    return ent.MMO;
                case ent.CO:
                    return ent.CO;
                case ent.TMO:
                    return ent.TMO;
                case ent.AMO:
                    return ent.AMO;
                case ent.IO:
                    return ent.IO;
                case ent.HMO:
                    return ent.HMO;
                case ent.FMO:
                    return ent.FMO;
                case ent.EMO:
                    return ent.EMO;
                case ent.GCS:
                    return ent.GCS;
                case ent.ECOMMERCEDB:
                    return 'ecommercedb';
                default:
                    return ent.STUDY + (item.ischarged === '1' || item.ischarged === true ? 'Premium' : 'Basis');
            }
        },
        getTitle: (state, getters) => (item) => {
            const name = getters.entityName(item.identity || item.idContentType);
            switch (name) {
                case ent.STATISTIC:
                case ent.FORECAST:
                    return item.catchline;
                case ent.TOPIC:
                    return item.pagetitle || item.pageTitle; // pagetitle is entry, pageTitle is promoted content
                case ent.XMO:
                case ent.DOSSIER:
                case ent.STUDY:
                case ent.BRANDREPORT:
                case ent.BRANCHREPORT:
                case ent.TOPLIST:
                case ent.COMPANYREPORT:
                    return item.pseudotitle;
                case ent.SURVEY:
                case ent.INFOGRAPHIC:
                case ent.DOSSIERPLUS:
                    return item.title;
                case ent.CMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.DMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.MMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.CO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.TMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.AMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.IO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.HMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.FMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.EMO:
                    return buildOutlookTitle(item.market, item.product);
                case ent.ECOMMERCEDB:
                    return item.title;
                default:
                    return item.title;
            }
        },
        getContent: (state, getters) => (item) => {
            const name = getters.entityName(item.identity || item.idContentType);
            switch (name) {
                case ent.DOSSIER:
                case ent.DOSSIERPLUS:
                case ent.SURVEY:
                case ent.TOPLIST:
                case ent.COMPANYREPORT:
                case ent.BRANDREPORT:
                case ent.BRANCHREPORT:
                    return item.subtitle;
                case ent.STATISTIC:
                case ent.FORECAST:
                    return item.graphheader;
                case ent.TOPIC:
                    return item.heading;
                case ent.XMO:
                case ent.STUDY:
                    return item.subtitle;
                case ent.INFOGRAPHIC:
                    return item.text;
                case ent.CMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.DMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.MMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.CO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.TMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.AMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.IO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.HMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.FMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.EMO:
                    return buildOutlookDescription(item.market, item.preposition);
                case ent.ECOMMERCEDB:
                    return item.subtitle;
                default:
                    return item.subtitle;
            }
        },
        getGroupType: (state, getters) => (item) => {
            const name = getters.entityName(item.identity || item.idContentType);
            switch (name) {
                case ent.STUDY:
                case ent.DOSSIER:
                case ent.DOSSIERPLUS:
                case ent.XMO:
                case ent.SURVEY:
                case ent.TOPLIST:
                case ent.BRANDREPORT:
                case ent.BRANCHREPORT:
                case ent.COMPANYREPORT:
                    return 'Report';
                case ent.FORECAST:
                    return 'Forecast';
                case ent.TOPIC:
                    return 'Topic';
                case ent.STATISTIC:
                    return 'Statistic';
                case ent.INFOGRAPHIC:
                    return 'Infographic';
                case ent.CMO:
                case ent.DMO:
                case ent.MMO:
                case ent.CO:
                case ent.TMO:
                case ent.AMO:
                case ent.IO:
                case ent.HMO:
                case ent.FMO:
                case ent.EMO:
                    return 'Market Insight';
                case ent.GCS:
                    return 'Consumer Insight';
                case ent.ECOMMERCEDB:
                    return ent.ECOMMERCEDBGROUP;
                default:
                    return 'Report';
            }
        },
        resultCount: state => () => {
            return state.totalFound;
        },
        getMetric: state => (name) => {
            try {
                return state.metrics[name];
            } catch (err) {
                return 0;
            }
        },
        getOriginalMetric: state => (name) => {
            try {
                return state.originalMetrics[name];
            } catch (err) {
                return 0;
            }
        },
        getShowSidebar: state => state.showSidebar,
        getTabGroup: state => state.tabGroup,
        searchArray: state => state.searchArray,
        resultArray: state => state.resultArray,
        masterTags: state => state.masterTags,
        promotedArray: state => state.promotedArray,
        repoArray: state => state.repoArray,
        currentPage: state => state.currentPage,
        totalFound: state => state.totalFound,
        totalPages: state => state.totalPages,
        topic: state => state.topics.length > 0 ? state.topics[0] : {},
        topics: state => state.topics.slice(1, 6),
        path: state => (item) => {
            const currentFocus = state.focus && state.focus !== '' && state.focus !== '-1' ? state.focus : '1';
            let currentRegionName = 'worldwide';
            if (state.countries && state.countries.length > 0) {
                if (state.countries.find(item => item.value === currentFocus)) {
                    currentRegionName = toSeoString(state.countries.find(item => item.value === currentFocus).label);
                }
            }
            const entity = state.entities.find(ent => (ent.id === Number(item.identity) || ([44, 45].includes(Number(item.identity)) && ent.id === Number(item.idmethod))));
            if (!entity) {
                console.warn('PATH ERROR, entity not found!', item);

                return '#';
            }
            if (entity.id === 26) {
                return item.url;
            }
            // get type of entry
            const type = allEntities.find(ent => ent.name === entity.key);
            if (!type) {
                console.warn('PATH ERROR, type not found!', entity);

                return '#';
            }

            const path = state.paths[ type.path ];
            if (!path) {
                console.warn('PATH ERROR, path not available for', type, entity, item);

                return '#';
            }
            let replacePath = path;
            replacePath = replacePath.replace(PREBUILT_URL, item.uri);
            replacePath = replacePath.replace('000', item.idcontent);
            replacePath = replacePath.replace('TITLE', uriEncode(item.uri || item.url || ''));
            replacePath = replacePath.replace('MARKET_URI', item.uri ? `${ item.uri }/${ currentRegionName }` : (item.url ? `${ item.url }/${ currentRegionName }` : ''));

            return replacePath;
        },
        sort: state => state.sort,
        focus: state => state.focus,
        location: state => state.location,
        tags: state => state.tags,
        intervalData: state => state.intervalData,
        interval: state => state.interval,
        accuracy: state => state.accuracy,
        category: state => state.category,
        subCategory: state => state.subCategory,
        branchesData: state => state.branchesData,
        regionsData: state => state.regionsData,
        isoregionsData: state => state.isoregionsData,
        isoregion: state => state.isoregion,
        resetFilterClicked: state => state.resetFilterClicked,
        applyFilterClicked: state => state.applyFilterClicked,
        userPermissionToGCS: state => state.userPermissionToGCS,
        histories: state => state.histories,
        entry: state => state.entry,
        entryUniqueId: state => state.entryUniqueId,
        metrics: state => state.metrics,
        selectedIndustries: state => state.selectedIndustries,
        selectedSubIndustries: state => state.selectedSubIndustries,
        selectedCategories: state => state.selectedCategories,
        response: state => state.response,
        tagsOptions: state => {
            return state.masterTags.sort((a, b) => { return b.count - a.count;}).slice(0, 10);
        },
        activeTab: state => state.activeTab,
        bannerInsights: state => state.bannerInsights,
    },

    actions: {
        updateCurrentPage({ commit }) {
            commit('setFigures', {
                currentPage: uparams && uparams.get('p') ? Number(uparams.get('p')) : 1,
            });
        },
        resetPageNumber({ commit }) {
            if (uparams && uparams.get('p')) {
                uparams.set('p', '1');
            }
            commit('setFigures', {
                currentPage: 1,
            });
        },
        resetTagsPage({ commit }) {
            if (uparams && uparams.get('p')) {
                uparams.set('p', '1');
            }
            setParam([ { name: 'p', value: 1 } ]);
            commit('setFigures', {
                currentPage: 1,
            });
        },
        setShow({ commit }, value) {
            commit('setShow', value);
        },
        updateLoaderStatus({ commit }, value) {
            commit('setLoading', value);
        },
        updateShowSidebar({ commit }, value) {
            commit('setShowSidebar', value);
            const bodyElement = document.querySelector('body');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE */
            if (value){
                bodyElement.classList.add('noScroll');
            } else if (!value) {
                bodyElement.classList.remove('noScroll');
            }
        },
        // set value and get results from server
        async setValue({ state, commit, rootGetters }, { value, timeout }) {
            commit('setValue', value);
            clearTimeout(state.requestTimeoutId);
            state.requestTimeoutId = setTimeout(async () => {
                if (value && value.length > 2) {
                    try {
                        const response = await axios.get(`${ state.paths.autocomplete }?language=${ rootGetters.language }&term=${ value }`);
                        commit('setSearchArray', response.data);
                    } catch (err) {
                        commit('setSearchArray', []);
                    }
                }
            }, timeout ?? 10);
        },

        // init search -> get items for all different groups to get the figures and first results
        async startSearch({ commit, dispatch }, { resetTags, noTopic, history, tags }) {
            commit('setApplyFilterClicked', false);
            try {
                commit('setLoading', true);
                if (resetTags) {
                    commit('resetTags', []);
                }
                // get search result
                if (history === false){
                    await dispatch('getPage', { mode: 'reset', history: history, tags: tags });
                } else {
                    await dispatch('getPage', { mode: 'reset', tags: tags });
                }

                // get entries for 'topic' -> no 'await' to get it in parallel and only, when search is changed (not when tab is selected for example)
                if (noTopic === undefined || !noTopic) {
                    dispatch('getTopic');
                }

            } catch (err) {
                commit('setLoading', false);
            }
            commit('setApplyFilterClicked', true);
        },

        // get figures for all areas -> do search without conditions
        async getFigures({ commit, state }, { entities, justSmart }) {
            // check if we have a search value
            try {
                // statistics default parameter

                const entitiesList = Object.keys(entities).map(key => {
                    return {
                        ...entities[key],
                        key,
                    };
                });
                // set entities
                let totalFound = 0;

                commit('setEntities', entitiesList);
                if (justSmart) {
                    //totalFound = counter.reduce((sum, item) => sum + Number(item.entitycount), 0);
                    totalFound = justSmart['totalFound'] === justSmart['totalFoundOriginal'] ? justSmart['totalFoundOriginal'] : justSmart['totalFound'];
                }

                // set figures
                await commit('setFigures', {
                    currentPage: state.currentPage,
                    totalFound: totalFound,
                });

            } catch (err) {
                commit('setEntities', []);
                commit('setFigures', {
                    currentPage: 1,
                    totalFound: 0,
                });
            }

        },

        // get page of results
        // parameters:  mode => reset -> sets currentpage to 0
        //              mode => relative -> type is the number of pages to get for- or backwards (positive or negative number)
        async getPage({ commit, state, dispatch, rootGetters }, { mode, num, history, tags }) {
            // calculate new page
            let newPage;
            switch (mode) {
                case 'reset':
                    newPage = state.currentPage;
                    commit('setFigures', { currentPage: newPage });
                    break;
                case 'reload':
                    newPage = state.currentPage;
                    break;
                default:
                    newPage = state.currentPage + num;
                    commit('setFigures', { currentPage: newPage });
                    break;
            }
            if (history === false) {
                commit('setFigures', {
                    currentPage: 1,
                });
            } else {
                setParam([ { name: 'p', value: newPage } ]);
            }

            const pars = parAll;

            // now we should have the start
            try {
                const response = await axios.get(searchUrl, { params: getPar(state, rootGetters, pars) });
                await commit('setOriginalMetrics', response.data.justSmart ? response.data.justSmart['metricOriginals'] : {});

                // set result in state
                await dispatch('setResultArray', { promoted: response.data.promotedContent ? response.data.promotedContent : [], value: response.data.results.mainselect });
                if (response.data.selectedCategories) {
                    commit('setSelectedCategories', response.data.selectedCategories);
                }
                commit('setTotalPages', response.data.lastPage);
                commit('setLoading', false);
                await commit('setResponse', response.data);

                dispatch('updateStatus', { tags });
            } catch (err) {
                commit('setLoading', false);
                if (err.response && err.response.status === 404) {
                    await commit('setOriginalMetrics', err.response.data.justSmart['metricOriginals']);
                    await commit('setSelectedCategories', err.response.data.selectedCategories);
                    await commit('setMetrics', Object.entries(err.response.data.justSmart['metrics']).toString() === Object.entries(err.response.data.justSmart['metricOriginals']).toString() ? err.response.data.justSmart['metricOriginals'] : err.response.data.justSmart['metrics']);
                    await commit('setResponse', {});
                    commit('setFigures', {
                        totalFound: err.response.data.justSmart['totalFoundOriginal'] === err.response.data.justSmart['totalFound'] ? err.response.data.justSmart['totalFoundOriginal'] : err.response.data.justSmart['totalFound'],
                    });
                } else {
                    commit('setMetrics', { market: 0, statistic: 0, report: 0, topic: 0 });
                    commit('setFigures', {
                        totalFound: 0,
                    });
                }

                commit('setPromoted', []);
                await dispatch('setResultArray', { promoted: [], value: [] });
                commit('setMasterTags', []);
                commit('setSelectedCategories', err.response.data.selectedCategories);
            }

        },
        updateStatus({ state, commit, dispatch }, { tags }) {
            // set promoted content
            dispatch('getFigures', {
                entities: state.response.entities,
                counter: state.response.results.counter,
                justSmart: state.response.justSmart,
                tags: tags,
            });
            commit('setPromoted', state.response.promotedContent || []);
            if (state.response.justSmart) {
                const metrics = Object.entries(state.response.justSmart['metrics']).toString() === Object.entries(state.response.justSmart['metricOriginals']).toString() ? state.response.justSmart['metricOriginals'] : state.response.justSmart['metrics'];
                commit('setMasterTags', state.response.justSmart['tags']);
                commit('setMetrics', metrics);
                commit('setOriginalMetrics', state.response.justSmart['metricOriginals']);
            }
            if (state.response.locationFocus) {
                // the result should be an Array with one entry
                const locationFocus = state.response.locationFocus;
                if (Array.isArray(locationFocus)) {
                    // use the first entry in the Array to set the focus
                    commit('setFocus', String(locationFocus[0]));
                }
            }
            if (state.response.entry) {
                commit('setEntryUniqueId', state.response.entryUniqueId);
                commit('setEntry', state.response.entry);
            }
        },
        async updateData({ state, commit, dispatch }) {
            // now we should have an update

            const uri = window.location.search.substring(1);
            const URLSearchParams = window.URLSearchParams;
            const param = new URLSearchParams(uri);
            if (param && param.get('sortMethod')) {
                await commit('setSort', param.get('sortMethod'));
            } else {
                await commit('setSort', 'idrelevance');
            }
            if (param && param.get('isRegionPref')) {
                await commit('setFocus', param.get('isRegionPref'));
                await deleteCookie('regionDefault');
                await new SafeCookie('regionDefault', param.get('isRegionPref'), EXPIRATION_YEAR).save();
            } else {
                await commit('setFocus', '-1');
                await deleteCookie('regionDefault');
            }
            if (param && param.get('q')) {
                if (state.query !== param.get('q')) {
                    await new SafeCookie('tags', '', EXPIRATION_DAY).save();
                    await commit('resetTags', []);
                }
                await commit('setValue', param.get('q'));
            }
            if (param && param.get('interval')) {
                await commit('setInterval', param.get('interval'));
            } else {
                await commit('setInterval', ['0']);
            }
            if (param && param.get('tabGroup')) {
                await commit('setContentType', param.get('tabGroup').split(','));
            } else {
                await commit('setContentType', []);
            }
            if (param && param.get('accuracy')) {
                await commit('setAccuracy', param.get('accuracy'));
            } else {
                await commit('setAccuracy', 'and');
            }
            if (param && param.get('region[]')) {
                await commit('setLocation', param.get('region[]').split(',').map(function(x) {
                    return parseInt(x);
                }));
            } else {
                await commit('setLocation', []);
            }
            if (param && param.get('isoregion')) {
                await commit('setIsoregion', param.get('isoregion'));
            } else {
                await commit('setIsoregion', '0');
            }
            if (param && param.get('idRelevance')) {
                commit('setIdRelevance', param.get('idRelevance'));
            } else {
                commit('setIdRelevance', '0');
            }
            if (param && param.get('language')) {
                commit('setLanguage', param.get('language'));
            } else {
                commit('setLanguage', defaultLanguageValue[window.stGlobals.locale]);
            }
            if (param && param.get('category[]')) {
                commit('setCategory', param.get('category[]'));
            } else {
                commit('setCategory', '0');
            }
            if (param && param.get('subCategory[]')) {
                commit('setSubCategory', param.get('subCategory[]'));
            } else {
                commit('setSubCategory', '0');
            }
            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);

        },
        // get entries for 'reports & studies' card in the middle
        async getReports({ commit, state, rootGetters }) {
            try {
                // get entries for report
                const reports = await axios.get(searchUrl, { params: getPar(state, rootGetters, parReportsLimit) });
                if (reports) {
                    commit('setRepoArray', reports.data.results.mainselect.slice(0, 3));
                }
            } catch (err) {
                commit('setRepoArray', []);
            }
        },

        // get topic
        async getTopic({ commit, state, rootGetters }) {
            // get entries for topic
            try {
                const topics = await axios.get(searchUrl, { params: getPar(state, rootGetters, parTopicsLimit, true) });
                commit('setTopic', topics.data.results.mainselect);
            } catch (err) {
                commit('setTopic', []);
            }
        },

        // set figures for currentPage, totalFound
        setFigures({ commit }, value) {
            commit('setFigures', value);
        },

        // set entities
        setEntities({ commit }, entitiesObj) {
            const entities = Object.keys(entitiesObj).map(key => {
                return {
                    ...entitiesObj[ key ],
                    key,
                };
            });
            commit('setEntities', entities);
        },

        // set countries for location focus
        setCountries({ commit }, value) {
            commit('setCountries', value);
        },

        // set promoted content
        setPromoted({ commit }, promoted) {
            commit('setPromoted', promoted);
        },
        // set parameter from history
        getHistory({ commit }) {
            const url = new URL(location);

            // get active page number
            const activePage = url.searchParams.get('p');
            if (activePage) {
                commit('setFigures', { currentPage: Number(activePage) });
            }
        },

        // set path
        setPath({ commit }, value) {
            commit('setPath', value);
        },
        setParamFunc({ state }) {
            setParam([
                { name: 'q', value: state.query },
                { name: 'p', value: 1 },
            ]);
        },
        // set Filters
        async setFilters({ commit, dispatch }, diffObj) {
            commit('setResetFilterClicked', false);
            commit('setApplyFilterClicked', true);
            if (Object.keys(diffObj).includes('sort')) {
                commit('setSort', diffObj['sort']);
                const value = diffObj['sort'];
                setParam([ { name: 'sortMethod', value }, { name: 'p', value: 1 } ]);
            }
            if (Object.keys(diffObj).includes('contentTypes')) {
                commit('setContentType', diffObj['contentTypes']);
                const value = diffObj['contentTypes'].join(',');
                setParam([ { name: 'tabGroup', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('isRegionPref')) {
                commit('setFocus', diffObj['isRegionPref']);
                const value = diffObj['isRegionPref'];
                setParam([ { name: 'isRegionPref', value }, { name: 'p', value: 1 }]);
                deleteCookie('regionDefault');
                new SafeCookie('regionDefault', value, EXPIRATION_YEAR).save();
            }
            if (Object.keys(diffObj).includes('countries')) {
                commit('setLocation', diffObj['countries']);
                const value = diffObj['countries'];
                setParam([ { name: 'region[]', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('isoregion')) {
                commit('setIsoregion', diffObj['isoregion']);
                const value = diffObj['isoregion'];
                setParam([ { name: 'isoregion', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('category')) {
                if (diffObj['category'].length > 1) {
                    commit('setCategory', diffObj['category'].filter(item => (item && item > 0)));
                    const value = diffObj['category'].filter(item => (item && item > 0));
                    setParam([ { name: 'category[]', value }, { name: 'p', value: 1 }]);
                } else {
                    commit('setCategory', diffObj['category']);
                    const value = diffObj['category'];
                    setParam([ { name: 'category[]', value }, { name: 'p', value: 1 }]);
                }
            }
            if (Object.keys(diffObj).includes('subCategory')) {
                commit('setSubCategory', diffObj['subCategory']);
                const value = diffObj['subCategory'];
                setParam([ { name: 'subCategory[]', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('interval')) {
                commit('setInterval', diffObj['interval']);
                const value = diffObj['interval'];
                setParam([ { name: 'interval', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('accuracy')) {
                commit('setAccuracy', diffObj['accuracy']);
                const value = diffObj['accuracy'];
                setParam([ { name: 'accuracy', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('idRelevance')) {
                commit('setIdRelevance', diffObj['idRelevance']);
                const value = diffObj['idRelevance'];
                setParam([ { name: 'idRelevance', value }, { name: 'p', value: 1 }]);
            }
            if (Object.keys(diffObj).includes('language')) {
                commit('setLanguage', diffObj['language']);
                const value = diffObj['language'];
                setParam([ { name: 'language', value }, { name: 'p', value: 1 }]);
            }
            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);
        },

        // reset all filters
        async resetFilters({ commit, dispatch }) {

            commit('setContentType', []);
            commit('setLocation', []);
            commit('setIsoregion', '0');
            commit('setInterval', ['0']);
            commit('setAccuracy', 'and');
            commit('setIdRelevance', '0');
            commit('setCategory', '0');
            commit('setSubCategory', '0');
            commit('setLanguage', defaultLanguageValue[window.stGlobals.locale]);
            setParam([{ name: 'accuracy', value: 'and' }, { name: 'tabGroup', value: [] }, { name: 'region[]', value: [] }, { name: 'isoregion', value: '0' }, { name: 'interval', value: ['0'] }, { name: 'idRelevance', value: '0' }, { name: 'language', value: defaultLanguageValue[window.stGlobals.locale] }, { name: 'p', value: 1 }, { name: 'category[]', value: '0' }, { name: 'subCategory[]', value: '0' }]);
            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setResetFilterClicked', true);
            commit('setApplyFilterClicked', false);
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },
        // set sort
        async setSort({ commit, dispatch }, value) {
            commit('setSort', value);
            setParam([ { name: 'sortMethod', value }, { name: 'p', value: 1 } ]);

            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },

        // set content type
        async setContentType({ commit, dispatch }, array) {
            commit('setContentType', array);
            const value = array.join(',');
            setParam([ { name: 'tabGroup', value }, { name: 'p', value: 1 }]);
            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },

        // set focus
        async setFocus({ commit, dispatch }, value) {
            commit('setFocus', value);
            setParam([ { name: 'isRegionPref', value }, { name: 'p', value: 1 }]);
            deleteCookie('regionDefault');
            new SafeCookie('regionDefault', value, EXPIRATION_YEAR).save();


            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },
        async setLocation({ state, commit, dispatch }, value) {
            commit('setLocation', value);
            setParam([ { name: 'region[]', value }]);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
        },
        async setCategory({ state, commit, dispatch }, value) {
            commit('setCategory', value);
            setParam([ { name: 'category[]', value }]);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
        },
        async setSubCategory({ state, commit, dispatch }, value) {
            commit('setSubCategory', value);
            setParam([ { name: 'subCategory[]', value }]);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
        },
        async setTags({ commit, dispatch }, value) {
            commit('setTags', value);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: true });
            dispatch('calculateWhichBannerToShow');
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },
        async unsetTags({ state, commit, dispatch }, value) {
            commit('unsetTags', value);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },
        async resetAllTags({ state, commit, dispatch }) {
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: true, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
        },
        async setArchive({ state, commit, dispatch }) {
            commit('setArchive', 0);
            setParam([ { name: 'archive', value: 0 }]);
            // get search result
            commit('setLoading', true);
            await dispatch('startSearch', { noFigures: false, noReport: false, noTopic: false, resetTags: false, history: false, tags: state.tags.length > 0 });
            commit('setLoading', false);
        },
        setHistory({ commit }, value) {
            commit('setHistory', value.data);
        },
        removeDuplicatesByProperty(root, { promoted, results, property }) {
            const uniquePropertyValues = Array.from(new Set(promoted.map(obj => obj[property].toString())));
            // Filter out duplicate objects from results based on the specified property
            const filteredResults = results.filter(obj => !uniquePropertyValues.includes(obj[property.toLowerCase()]));

            return filteredResults;
        },
        async setResultArray({ commit, dispatch }, { promoted, value }) {
            if (promoted.length > 0) {
                const property = 'idContent';
                const resultsWithoutPromoted = await dispatch('removeDuplicatesByProperty', { promoted: promoted, results: value, property: property });
                commit('setResultArray', resultsWithoutPromoted);
            } else {
                commit('setResultArray', value);
            }
        },
        setSelectedIndustries({ commit }, value) {
            commit('setSelectedIndustries', value);
        },
        setSelectedSubIndustries({ commit }, value) {
            commit('setSelectedSubIndustries', value);
        },
        setSubCategoryInitial({ state, commit }) {
            if (state.selectedCategories) {
                const keysForSubCategory = Object.keys(state.selectedCategories).filter((key) => {
                    return state.selectedCategories[key].parentId !== null;
                });
                commit('setSubCategory', keysForSubCategory);
            }
        },
        async setLanguage({ commit, dispatch }, value) {
            commit('setLanguage', value);
            setParam([{ name: 'language', value }, { name: 'p', value: 1 }]);

            // get search result
            commit('setLoading', true);
            await dispatch('getPage', { mode: 'reset', history: false });
            commit('setLoading', false);
            // push to data layer
            setTimeout(() => {
                pushToDataLayer();
            }, 5000);
        },
        setActiveTab({ commit }, value) {
            commit('setActiveTab', value);
        },
        setBannerInsights({ commit }, value) {
            commit('setBannerInsights', value);
        },
        async calculateWhichBannerToShow({ commit, state }) {
            const insightArray = [];

            try {
                if (state.tabGroup.length === 0) {
                    if (state.query === '') {
                        insightArray.push(CONSUMER, MARKET);
                    } else {
                        await commit('setBannerInsights', EMPTY_ARRAY);
                        this.addToInsight(insightArray, CONSUMER, Number(this.getOriginalMetric(GCS)) + Number(this.getOriginalMetric(GCSREPORT)));
                        this.addToInsight(insightArray, MARKET, this.getOriginalMetric(MARKET));
                    }
                }

                if (insightArray.length === 1) {
                    insightArray.push(DEFAULT);
                }
                commit('setBannerInsights', insightArray);
            } catch (err) {
                commit('setBannerInsights', insightArray);
            }
        },
        addToInsight(array, metric, value) {
            if (Number(value) > 0) {
                array.push(metric);
            }
        },
    },
    mutations: {
        setShow(state, value) {
            state.show = value;
        },
        setLoading(state, value) {
            state.loading = value;
        },
        setValue(state, value) {
            state.query = value;
        },
        setMetrics(state, value) {
            state.metrics = value;
        },
        setOriginalMetrics(state, value) {
            state.originalMetrics = value;
        },
        setEntities(state, entities) {
            state.entities = entities;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setPromoted(state, promoted) {
            state.promotedArray = promoted.map(item => {
                return {
                    ...item,
                    identity: item.idContentType,
                    ischarged: item.charged,
                    pagetitle: item.pageTitle,
                    pseudotitle: item.pseudoTitle,
                    subtitle: item.subTitle,
                    idcontent: item.idContent,
                };
            });
        },
        setSearchArray(state, value) {
            state.searchArray = value;
        },
        setResult(state, value) {
            state.result = value.map(item => {
                return {
                    data: item.data.results.mainselect,
                    totalFound: item.data.totalFound,
                    name: item.name,
                };
            });
        },
        setResultArray(state, value) {
            state.resultArray = value;
        },
        setMasterTags(state, value) {
            let tags = [];
            if (value !== []) {
                Object.keys(value).forEach(function(key) {
                    tags.push(value[key]);
                });
                tags = tags.flat();
                state.masterTags = Object.values(tags.reduce((acc, { tag, count }) => {
                    acc[tag] = acc[tag] || { tag, count: 0 };
                    acc[tag].count += count;

                    return acc;
                }, {}));
            } else {
                state.masterTags = [];
            }
        },
        setRepoArray(state, value) {
            state.repoArray = value;
        },
        setTopic(state, value) {
            state.topics = value;
        },
        setFigures(state, value) {
            if (value.currentPage !== undefined) {
                state.currentPage = value.currentPage;
            }
            if (value.totalFound !== undefined) {
                state.totalFound = value.totalFound;
            }
        },
        setPath(state, path) {
            state.paths = path;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
        setFocus(state, focus) {
            state.focus = focus;
        },
        setContentType(state, contentType) {
            state.tabGroup = contentType;
        },
        setLocation(state, location) {
            state.location = location;
        },
        setTags(state, tags) {
            state.tags.push(tags);
            new SafeCookie('tags', state.tags, EXPIRATION_DAY).save();
        },
        unsetTags(state, tags) {
            state.tags = state.tags.filter(item => item !== tags);
            new SafeCookie('tags', state.tags, EXPIRATION_DAY).save();
        },
        resetTags(state, value) {
            state.tags = value;
            new SafeCookie('tags', '', EXPIRATION_DAY).save();
        },
        setArchive(state, value) {
            state.archive = value;
        },
        setTotalPages(state, value) {
            state.totalPages = value;
        },
        setShowSidebar(state, value) {
            state.showSidebar = value;
        },
        setIntervalData(state, value) {
            value.map(val => {
                val.label = intervalLabels[val.label] ?? val.label;
            });
            state.intervalData = value;
        },
        setBranchesData(state, value) {
            value.map(val => {
                val.label = categoryLabels[val.label] ?? val.label;
            });
            state.branchesData = value;
        },
        setInterval(state, value) {
            state.interval = value;
        },
        setAccuracy(state, value) {
            state.accuracy = value;
        },
        setIdRelevance(state, value) {
            state.idRelevance = value;
        },
        setLanguage(state, value) {
            state.language = value;
        },
        setCategory(state, value) {
            state.category = value;
        },
        setSubCategory(state, value) {
            state.subCategory = value;
        },
        setRegionsData(state, value) {
            state.regionsData = value;
        },
        setIsoregionsData(state, value) {
            value.map(val => {
                val.label = categoryLabels[val.label] ?? val.label;
            });
            state.isoregionsData = value;
        },
        setIsoregion(state, value) {
            state.isoregion = value;
        },
        setResetFilterClicked(state, value) {
            state.resetFilterClicked = value;
        },
        setApplyFilterClicked(state, value) {
            state.applyFilterClicked = value;
        },
        setResponse(state, value) {
            state.response = value;
        },
        setUserPermission(state, value) {
            state.userPermissionToGCS = value;
        },
        setHistory(state, object) {
            for (const key in object) {
                if (object[key]['entryUniqueId'] === '') {
                    delete object[key];
                }
            }
            state.histories = object;
        },
        setEntryUniqueId(state, value) {
            state.entryUniqueId = value;
        },
        setEntry(state, value) {
            state.entry = value;
        },
        setSelectedIndustries(state, value) {
            state.selectedIndustries = value;
        },
        setSelectedSubIndustries(state, value) {
            if (!state.selectedSubIndustries.includes(value)) {
                state.selectedSubIndustries.push(value);
            }
        },
        setSelectedCategories(state, value) {
            state.selectedCategories = value;
        },
        setActiveTab(state, value) {
            state.activeTab = value;
        },
        setSelectedFilterNumber(state, value) {
            state.selectedFilterNumber = value;
        },
        setBannerInsights(state, value) {
            state.bannerInsights = value;
        },
    },
};
